import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Pusher from 'pusher-js';

class StepProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props,
      fulfillments: props.fulfillments
    };
  }
  componentDidMount(){
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
      cluster: 'us2',
      encrypted: true
    });
    const channel = pusher.subscribe('order');
    channel.bind('update', data => {
      this.setState({fulfillments: data.message.fulfillments[0]});
    });
  }
  render() {
    var progress = 0;
    var status = "";
    switch (this.state?.fulfillments?.state) {
      case "PROPOSED": status = "We got your order!"; progress = 0; break;
      case "RESERVED": status = "Cooking..."; progress = 34; break;
      case "PREPARED": status = "Your order is ready!"; progress = 67; break;
      case "COMPLETED": status = "Picked up!"; progress = 100; break;
      default: status = "Order received"; progress = 0; break;
    }
    return (
      <div className="text-center">
        <ProgressBar
          percent={progress}
          filledBackground="#611A20"
          height="2px"
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <div className="border-color6 p-1 rounded-circle bg-color2">
                <div className={`${accomplished ? "bg-color6 rounded-circle" : ""}`} style={{ height: "30px", width: "30px" }}>
                </div>
              </div>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <div className="border-color6 p-1 rounded-circle bg-color2">
                <div className={`${accomplished ? "bg-color6 rounded-circle" : ""}`} style={{ height: "30px", width: "30px" }}>
                </div>
              </div>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <div className="border-color6 p-1 rounded-circle bg-color2">
                <div className={`${accomplished ? "bg-color6 rounded-circle" : ""}`} style={{ height: "30px", width: "30px" }}>
                </div>
              </div>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <div className="border-color6 p-1 rounded-circle bg-color2">
                <div className={`${accomplished ? "bg-color6 rounded-circle" : ""}`} style={{ height: "30px", width: "30px" }}>
                </div>
              </div>
            )}
          </Step>
        </ProgressBar>
        <br /><br />
        <small className="poppins text-color6 fw-semibold">{status}</small>
      </div>
    );
  }
}

export default StepProgressBar;