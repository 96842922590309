import ReactGA from "react-ga4";
import React from 'react';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import axios from 'axios';

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import SquarePaymentsScript from './SquarePaymentsScript'; // Load Square Payments script
import GoogleMapsPlacesScript from './GoogleMapsPlacesScript';
import Home from './Home/Home';
import Footer from './Footer/Footer';
import Contact from './Contact/Contact';
import Shop from './Shop/Shop';
import SelectCheckout from './SelectCheckout/SelectCheckout';
import Pay from './Pay/Pay';
import Order from './Order/Order';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Open from './Open/Open';
import Register from "./Register/Register";
import Login from "./Login/Login";
import Profile from './Profile/Profile';
import ForgotPassword from './ForgotPassword/ForgotPassword'
import ResetPassword from './ResetPassword/ResetPassword'
// import GiftCards from "./GiftCards/GiftCards";
// import LoadingScreen from '../LoadingScreen/LoadingScreen';
// import loadingImage from './../assets/minys/images/loading.gif'

if (process.env.REACT_APP_NAME === "Miny's Mexican Restaurant")
  require('./assets/minys.css');
if (process.env.REACT_APP_NAME === "El Santo Kamaron")
  require('./assets/esk.css');

function OrderPage(params) {
  let { orderId } = useParams();
  return <Order catalog={params?.catalog} locations={params?.locations} location={params?.location} orderId={orderId} />
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      catalog: null,
      catering: null,
      location: null,
      locationsStatus: null,
      attributes: null,
      multimedia: null,
      googleMapsPlacesLoaded: false,
      user: null,
      cards: [],
      profileChecked: false,
      loaded: false
    };
    this.setLocation = this.setLocation.bind(this);
  }

  componentDidMount() {
    // First check localStorage for existing location
    const savedLocation = localStorage.getItem('selectedLocation');
    if (savedLocation) {
      this.setState({ location: savedLocation }, () => {
        this.setLocation(savedLocation);
        this.getCatalogFromLocation(savedLocation);
      });
    }
    
    // Then get all locations
    this.getLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    // Only update if location actually changed
    if (this.state.location && prevState.location !== this.state.location) {
      this.setState({ catalog: null, loaded: false });
      this.setLocation(this.state.location);
      this.getCatalogFromLocation(this.state.location);
      // Save to localStorage whenever location changes
      localStorage.setItem('selectedLocation', this.state.location);
    }
  }

  async getCoordinatesFromAddress(address) {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const location = data.results[0].geometry.location;
      return { lat: location.lat, lng: location.lng };
    } else {
      throw new Error('No se pudieron obtener las coordenadas');
    }
  };

  haversineDistance(coords1, coords2) {
    const toRad = (x) => (x * Math.PI) / 180;

    const lat1 = coords1.lat;
    const lon1 = coords1.lng;
    const lat2 = coords2.lat;
    const lon2 = coords2.lng;

    const R = 6371; // Radio de la Tierra en kilómetros
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    return d; // Distancia en kilómetros
  };


async getUserLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        resolve({ lat: latitude, lng: longitude });
      },
      (error) => {
        // console.error("Error getting user location:", error);
        reject(error); // Reject the promise on error
      }
    );
  });
}

async getLocations() {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + 'location');
    const locations = res?.data?.locations || res?.data?.result?.locations;

    if (locations) {
      this.setState({ locations });

      // Check if we already have a location set
      const existingLocation = localStorage.getItem('locationId');
      if (existingLocation) {
        // If we have an existing location, don't proceed with automatic location detection
        return;
      }

      // Only proceed with location detection if we don't have an existing location
      const userLocation = await this.getUserLocation();
      if (!userLocation || !userLocation.lat || !userLocation.lng) {
        console.error("User location is not available.");
        return;
      }

      let min_distance = null;

      // Loop through locations to find the closest one
      for (const loc of locations) {
        // Construct the full address
        const address = [
          loc.address.address_line_1,
          loc.address.locality,
          loc.address.administrative_district_level_1,
          loc.address.postal_code,
          loc.address.country
        ];

        // Get coordinates for the location's address
        const coords = await this.getCoordinatesFromAddress(address.join(", "));
        // console.log("Location Coords:", coords);

        // Get distance from Google Distance Matrix API
        const res = await axios.post(`${process.env.REACT_APP_API_URL}location/getDistance`, {
          coords1: userLocation,
          coords2: coords
        });
        
        if (res.data.success) {
          const distance = res.data.distance;
          // Do something with the distance
          if (distance !== null) {
            if (min_distance) {
              if (distance < min_distance.distance) {
                min_distance = { loc, distance };
              }
            } else {
              min_distance = { loc, distance };
            }
          }
        } else {
          console.error('Error calculating distance:', res.data.message);
        }
        
        // console.log("Distance:", distance);

      }

      // Check if min_distance is defined after the loop
      if (!min_distance) {
        return;
      }

      // Only prompt for location selection if we're on the home page and don't have an existing location
      if (window.location.pathname === "/") {
        if (window.confirm(`Your closest location is ${min_distance?.loc?.address?.locality}, do you want to choose this location?`)) {
          this.setLocation(min_distance?.loc?.id);
          setTimeout(() => {
            window.location = "/menu";
          }, 1000);
        }
      } else {
        this.setLocation(min_distance?.loc?.id);
      }
      ReactGA.initialize(process.env.REACT_APP_GA);
    }
  } catch (err) {
    console.error("Error in getLocations:", err);
  }
}




  getStoreStatus() {
    axios.post(process.env.REACT_APP_API_URL + 'location/list-locations', {
      'location': process.env.REACT_APP_NAME
    }).then(res => {
      this.setState({ locationsStatus: res.data });
    }).catch(err => console.log(err));
  }

  getCatalogFromLocation = (location) => {
    axios.get(`${process.env.REACT_APP_API_URL}catalog?location=${location}`)
      .then(res => {
        if (res.data) {
          this.setState({ catalog: res.data });
        } else {
          // Handle case where response data is empty or undefined
          console.log("Empty response data");
        }
      })
      .catch(err => {
        console.log("Error fetching catalog:", err);
        // Handle error, e.g., display error message to user
      });
  }

  // getCateringFromLocation = (location) => {
  //   axios.get(process.env.REACT_APP_API_URL+`catalog/catering?location=${location}`)
  //       .then(res => {this.setState({catering: res?.data})
  //     })
  //       .catch(err => console.log(err));
  // }

  setLocation = (location, ignoreLoaded) => {
    localStorage.setItem('locationId', location);
    if (location && (!this.state.loaded || (ignoreLoaded))) {
      axios.post(process.env.REACT_APP_API_URL + 'location/check', {
        'location': location
      }).then(res => {
        const multimediaArray = Array.isArray(res.data?.multimedia) ? res.data.multimedia : []; // Ensure multimedia is an array or initialize with an empty array
        let urlsArray = [];

        if (multimediaArray.length > 0) {
          urlsArray = multimediaArray.map(item => item?.attributes?.url);
          this.setState({ multimedia: urlsArray });
        } else {
          this.setState({ multimedia: null });
        }
        if (res?.data?.message === 'This store is closed') {
          this.setState({ open: false, location, attributes: res.data.attributes })
        } else {
          this.setState({ open: res.data?.attributes?.open, location, attributes: res.data.attributes })
        }
      }).catch(err => console.error(err));
      this.setState({ loaded: true });
    }
  }

  async getProfile() {
    const jwt = localStorage.getItem("jwt");
    try {
      if (jwt) {
        const response = await axios.post(process.env.REACT_APP_API_URL + "customer/get-user-details", { jwt });
        if (response) {
          const responseCards = await axios.post(process.env.REACT_APP_API_URL + "customer/list-cards", { squareId: response?.data?.squareId });
          if (responseCards?.data?.cards) {
            this.setState({
              user: response?.data,
              cards: responseCards?.data?.cards,
              profileChecked: true
            });
          }
          else {
            this.setState({
              user: response?.data,
              profileChecked: true
            });
          }
        }
      }
    }
    catch (error) {
      localStorage.setItem("jwt", "");
      localStorage.removeItem("jwt");
      localStorage.removeItem("squareId");
    }
  }

  render() {
    if (!this.state.profileChecked) {
      this.getProfile();
    }
    const { user, cards, catalog, locations, location, open, locationsStatus, attributes, multimedia } = this.state;
    return (
      <div className="App">
        <SquarePaymentsScript />
        <GoogleMapsPlacesScript onScriptLoad={() => this.setState({ googleMapsPlacesLoaded: true })} />
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home locations={locations} setLocation={this.setLocation} attributes={attributes} />} />
            <Route path='/menu' element={<Shop user={user} getUser={this.getProfile} open={open} catalog={catalog} locations={locations} location={location} setLocation={this.setLocation} attributes={attributes} multimedia={multimedia} />} />
            {/* <Route path='/catering' element={<Shop open={open} catalog={catering} locations={locations} location={location} setLocation={this.setLocation} attributes={ attributes } />}/> */}
            <Route path='/checkout' element={<SelectCheckout user={user} cards={cards} open={open} catalog={catalog} locations={locations} location={location} setLocation={this.setLocation} attributes={attributes} />} />
            <Route path='/pay' element={<Pay open={open} cards={cards} catalog={catalog} locations={locations} location={location} setLocation={this.setLocation} attributes={attributes} />} />
            <Route path='/contact' element={<Contact locations={locations} attributes={attributes} />} />
            <Route path='/order/:orderId' element={<OrderPage locations={locations} location={location} attributes={attributes} catalog={catalog} />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/open' element={<Open open={open} locations={locations} openOrClose={this.openOrClose} locationsStatus={locationsStatus} />} />
            <Route path='/signup' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/profile' element={<Profile getProfile={(e) => this.getProfile()} locations={locations} cards={cards} user={user} />} />
            {/* <Route path='/gift-cards' element={<GiftCards/>}/> */}
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
          </Routes>
        </BrowserRouter>
        <Footer locations={locations} location={location} />
      </div>
    );
  }
}

export default App;