import React from 'react';
import './assets/Contents.css';

class Contents extends React.Component{
    render() {
        let locations = [];
        if(this.props?.locations?.length > 0){
            this.props?.locations?.forEach(location =>
                locations.push(
                    <div className="col" key={location.address.locality}>
                        <div className='bg-color5'>
                            <div id={"flush-collapse"+location.id} className="accordion-collapse collapse" aria-labelledby={"flush-heading"+location.id} data-bs-parent={"#accordionFlush"}>
                                <div className="accordion-body text-white poppins">
                                    <h5>{location.address.locality}</h5>
                                    <small>{location?.address?.address_line_1}, {location?.address?.locality}, {location?.address?.administrative_district_level_1} {location?.address?.postal_code}</small>
                                    <a href={"tel:"+location.phone_number} className={'text-reset'}> 
                                        <p>
                                            {location.phone_number}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            );
        }
        return (
            <div className='px-5'>
                <div className="row px-5">
                    {locations}
                    <div className="col"></div>
                </div>
            </div>
        );
    }
}

export default Contents;