import React from 'react';
import { Link } from 'react-router-dom';
import './assets/Home.css';
import Locations from './components/locations/Locations';
import data from '../assets/resources';
import ReactGA from "react-ga4";

class Home extends React.Component {
    componentDidMount() {
        document.title = `Home | ${process.env.REACT_APP_NAME}`;
        document.querySelector('meta[name="description"]').setAttribute("content", process.env.REACT_APP_DESCRIPTION);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }

    render() {
        return (
            <div className="background font-poppins">
                <div className="logo-container">
                    <Link to='/'><img className="logo-image" src={data.corner_logo} alt="" /></Link>
                </div>
                <div className="container">
                    <div className="row justify-content-center mb-4">
                        <div className="col-12 col-md-4">
                            <img className="img-fluid fade-in-text" src={data.home_image} alt="" />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <Locations locations={this.props?.locations} setLocation={this.props?.setLocation} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;