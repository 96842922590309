import React from "react";
import data from "../assets/resources";
import ReactGA from "react-ga4";
import axios from "axios";
import { loadGoogleMapsScript } from "../utils/loadGoogleMapsScript";
import AddressForm from "./AddressForm/AddressForm";
import CardForm from "./CardForm/CardForm";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withNavigation from '../withNavigation/withNavigation';
import './assets/Profile.css'

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      cards: [],
      hasFetchedProfile: false,
      googleLoaded: false,
      googleMapsInitialized: false,
    };
  }
  async componentDidMount() {
    document.title = `Profile | ${process.env.REACT_APP_NAME}`;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", process.env.REACT_APP_DESCRIPTION);
    }
    try {
      await loadGoogleMapsScript(); // Load the Google Maps script only once
      await this.waitForGoogleMaps(); // Wait for Google Maps to be fully initialized
      this.setState({ googleLoaded: true, googleMapsInitialized: true }); // Mark as loaded once the script is available
    } catch (error) {
      console.error("Error loading Google Maps script:", error);
    }
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: document.title,
    });
    await this.getProfile(); // Fetch profile on mount

  }

  waitForGoogleMaps = () => {
    return new Promise((resolve, reject) => {
      const checkGoogle = () => {
        if (window.google && window.google.maps && window.google.maps.places) {
          resolve();
        } else {
          setTimeout(checkGoogle, 100);
        }
      };
      checkGoogle();
      // Timeout after 10 seconds
      setTimeout(() => reject(new Error('Google Maps failed to load')), 10000);
    });
  };

  async getProfile() {
    const jwt = localStorage.getItem("jwt");
    // If no JWT, redirect to login
    if (!jwt) {
      window.location.replace("/login"); // Redirect to login page
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}customer/get-user-details`,
        { jwt }
      );
      const responseCards = await axios.post(
        `${process.env.REACT_APP_API_URL}customer/list-cards`,
        { squareId: response?.data?.squareId }
      );
      this.setState({
        user: response?.data,
        cards: responseCards?.data?.cards || [],
      });
    } catch (error) {
      console.error("Error fetching profile:", error);
      if (this.props.navigate) {
        this.props.navigate("/login");
      }
    }
  }

  async deleteCard(cardId) {
    const toastr = toast.loading("Deleting...", { autoClose: 5000 });
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "customer/disable-card",
        { cardId }
      );
      if (response?.data?.card) {
        toast.update(toastr, { render: "Card deleted successfully!", type: "success", isLoading: false, autoClose: 1000 });
        this.getProfile();
      }
    } catch (error) {
      console.log(error);
      toast.update(toastr, { render: "Internal Error", type: "error", isLoading: false, autoClose: 1000 });
    }
  }


  async deleteAddress(addressId) {
    const toastr = toast.loading("Deleting...", { autoClose: 5000 });
    const jwt = localStorage.getItem("jwt");
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "customer/delete-address",
        { jwt, addressId }
      );
      if (response?.data?.data?.id) {
        toast.update(toastr, { render: "Address deleted succesfully!", type: "success", isLoading: false, autoClose: 1000 });
        this.props.getProfile();
      }
    } catch (error) {
      toast.update(toastr, { render: "Internal Error", type: "error", isLoading: false, autoClose: 1000 });
    }
  }

  render() {
    const { user, cards,googleLoaded } = this.state;
    var _addresses = [];
    user?.addresses?.forEach((address) => {
      _addresses.push(
        <div className="bg-color3 p-3 rounded" key={"address_" + address.id}>
          <div className="row">
            <div className="col-11">
              <p>
                {address.Nickname}
                <br />
                {address.Address}
              </p>
            </div>
            <div className="col-1">
              <button className="btn btn-transparent" onClick={(e) => this.deleteAddress(address.id)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-trash text-danger" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                </svg>
              </button>
            </div>
          </div>

        </div>
      );
    });

    var _cards = [];
    cards?.forEach((card) => {
      var img = null;
      switch (card.card_brand) {
        case "VISA":
          img = data.VisaLogo;
          break;
        case "MASTERCARD":
          img = data.MCLogo;
          break;
        default:
          img = data.VisaLogo;
          break;
      }
      _cards.push(
        <div className="row bg-color3 p-3 rounded" key={"card_" + card.id}>
          <div className="col-2">
            <img className="img-fluid" src={img} alt="" />
          </div>
          <div className="col-9">
            <p>
              {card.cardholder_name}
              <br />
              XXXX-XXXX-XXXX-{card.last_4}
              <br />
              Exp: {card.exp_month}/{card.exp_year}
            </p>
          </div>
          <div className="col-1">
            <button className="btn btn-transparent" onClick={(e) => this.deleteCard(card.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-trash text-danger" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
              </svg>
            </button>
          </div>
        </div>
      );
    });

    return (
      <div className="bg-color2 pb-5">
        <div className="corner-logo">
          <a href="/">
            <img className="img-fluid" src={data.corner_logo} alt="" />
          </a>
        </div>
        <div className="container pt-5 text-color6">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            limit={2}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="colored"
          />
          <div className="row">
            <div className="col-12 col-md-">
              <h2>
                Hi {user?.name} {user?.lastName}!
              </h2>
              <h5>
                <strong>Email:</strong> {user?.email}
              </h5>
              <h5>
                <strong>Phone:</strong> {user?.phone}
              </h5>
            </div>
          </div>
          <div className="row">
            
            <div className="col-12 col-md-6 user-addresses">
              <div className="accordion" id="accordionAddress">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-color3 text-color6"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseAddressList"
                      aria-expanded="false"
                      aria-controls="collapseAddressList"
                    >
                      Saved addresses
                    </button>
                  </h2>
                  <div
                    id="collapseAddressList"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="accordion-body bg-color2 p-2">
                      {_addresses}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed bg-color3 text-color6"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNewAddress"
                      aria-expanded="false"
                      aria-controls="collapseNewAddress"
                    >
                      Add new address
                    </button>
                  </h2>
                  <div
                    id="collapseNewAddress"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionAddress"
                  >
                    <div className="accordion-body bg-color2">
                      <AddressForm
                        userId={user?.id}
                        update={() => this.getProfile()}
                        googleLoaded={googleLoaded}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 user-cards">
              <div className="accordion" id="accordionCards">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-color3 text-color6"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseCardList"
                      aria-expanded="false"
                      aria-controls="collapseCardList"
                    >
                      Saved cards
                    </button>
                  </h2>
                  <div
                    id="collapseCardList"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="accordion-body bg-color2 p-2">{_cards}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed bg-color3 text-color6"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNewCard"
                      aria-expanded="false"
                      aria-controls="collapseNewCard"
                    >
                      Add Card
                    </button>
                  </h2>
                  <div
                    id="collapseNewCard"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionCards"
                  >
                    <div className="accordion-body bg-color2">
                      <CardForm
                        getProfile={() => this.getProfile()}
                        locations={this.props.locations}
                        user={user}
                        update={() => this.getProfile()}
                        googleLoaded={googleLoaded}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <div className="row">
          <div className="col-12 col-md-6">
              <div className="col-6 col-md-6">
                <button
                  className="btn bg-color4 poppins w-20 fw-semibold text-color6"
                  onClick={() => window.location.href = '/menu'}>
                  Click here to continue your order.
                </button>
              </div>
              <br/>
              <small>
                <Link
                  className="text-color6"
                  to="/"
                  onClick={(e) => {
                    localStorage.removeItem("jwt");
                    localStorage.removeItem("squareId");
                    alert("Session closed");
                  }}
                >
                  Sign out{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                    />
                  </svg>
                </Link>
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNavigation(Profile);
