import React from 'react';
import { NumericFormat } from 'react-number-format';
import './assets/CheckoutModal.css';
import data from '../../assets/resources';

class CheckoutModal extends React.Component {

    state = {
        total: (this.props?.product?.itemData?.variations?.length > 1) ? 0 : this.props?.product?.itemData?.variations[0]?.itemVariationData?.priceMoney?.amount,
        modifiers_total: this.getModifiersTotal(),
        product_to_update: {
            "uid": this.props?.item?.uid,
            "quantity": this.props?.item?.quantity,
            "modifiers": this.getModifiers(),
            "variation": this.props?.item?.catalogObjectId,
            "modifiers_to_delete":[],
            "modifiers_to_add":[]
        },
        variations_required: (this.props?.product?.itemData?.variations?.length > 1)
    }

    getModifiers(){
        var modifiers = [];
        this.props?.product?.itemData?.modifierListInfo?.forEach(modifier => {
            modifier.details.modifiers.forEach(option => {
                if (this.props?.item?.modifiers?.find( m => m.catalogObjectId === option.id)){
                    modifiers.push({
                        "catalogObjectId": option?.id,
                        "modifierListId": option?.modifierData?.modifierListId,
                        "quantity": "1"
                    });
                }
            });
        });
        return modifiers;
    }

    getModifiersTotal(){
        var total = 0;
        this.props?.product?.itemData?.modifierListInfo?.forEach(modifier => {
            modifier.details.modifiers.forEach(option => {
                if (this.props?.item?.modifiers?.find( m => m.catalogObjectId === option.id)){
                    total += parseInt(option?.modifierData?.priceMoney?.amount);
                }
            });
        });
        return total;
    }

    render() {var modifiers = [];
        if (this.props?.product?.itemData?.modifierListInfo){
            this.props?.product?.itemData?.modifierListInfo?.forEach(modifier => modifiers.push(this.modifier(modifier)));
        }

        var button;
        if (this.state?.variations_required){
            button = <button className='btn w-100 bg-color4 text-color6 poppins fw-semibold' data-bs-dismiss="modal" onClick={this.updateItem} disabled>Save</button>
        }
        else{
            button = <button className='btn w-100 bg-color4 text-color6 poppins fw-semibold' data-bs-dismiss="modal" onClick={this.updateItem}>Save</button>
        }

        return (
            <div className="modal fade" id={"checkoutModal" + this.props?.item?.uid} tabIndex="-1" aria-labelledby="productModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className='bg-color2'>
                            <div className="text-end">
                                <button data-bs-toggle="collapse" data-bs-dismiss="modal" className='btn text-color6'>
                                    <img src={data.X} alt="" />
                                </button>
                            </div>
                            <div className="image-background" style={{ height: '280px' }}>
                                <img className='w-100' src={this.props?.product?.itemData?.imageDetails[0]?.url} alt="" />
                            </div>
                            {this.state?.catalogObjectId}
                            <h3 className='text-color6 poppins fw-bold m-3'>{this.props?.product?.itemData?.name}</h3>
                            <h5 className='text-color6 poppins fw-bold mx-3'><NumericFormat value={(parseInt(this.state?.total) + this.state?.modifiers_total) * parseInt(this.state?.product_to_update?.quantity) / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></h5>
                            <p className='text-color6 poppins mx-3'><small>{this.props?.product?.itemData?.description}</small></p>
                            {this.variations(this.props?.product?.itemData?.variations)}
                            {modifiers}
                            {/*<div className='notes-section'>
                                <div className="bg-color7 p-3 text-color6 poppins fw-semibold mt-3">
                                    Notes
                                </div>
                                <div className='options px-3 pt-2'>
                                    <textarea name="notes" id="" rows="3" className='notes-input bg-color2 text-color6 w-100' placeholder="Type note" onChange={(e) => this.setNotes(e)}></textarea>
                                </div>
                            </div>*/}
                        </div>
                        <div className='row m-2'>
                            <div className="col-4">
                                <span>
                                    <img className='quantity-button' onClick={(e) => this.setQuantity(parseInt(this.state?.product_to_update?.quantity) + 1)} src={data.AddCart} alt="" />
                                </span>
                                <span className='fw-semibold text-color6 mx-3'>{this.state?.product_to_update?.quantity}</span>
                                <span>
                                    <img className='quantity-button' onClick={(e) => this.setQuantity(parseInt(this.state?.product_to_update?.quantity) - 1)} src={data.ReduceCart} alt="" />
                                </span>
                            </div>
                            <div className="col-8">
                                {button}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    modifier(modifier) {
        var inputs = [];
        modifier.details.modifiers.forEach(option => {
            var op;
            if (modifier.details.selectionType === 'SINGLE'){
                op = (<input type='radio' id={option.id} name={option.modifierData.modifierListId} onChange={((e) => this.setModifier(e, option, 'single'))} checked={this.state?.product_to_update?.modifiers?.find(mod => mod.catalogObjectId === option.id)}/>);
            }
            else if (modifier.details.selectionType === 'MULTIPLE'){
                op = (<input type='checkbox' id={option.id} name={option.id} value={option.modifierListId + "-" + option.id} onChange={((e) => this.setModifier(e, option, 'multiple'))} checked={this.state?.product_to_update?.modifiers?.find(mod => mod.catalogObjectId === option.id)}/>);
            }
            var amount = [];
            if (option.modifierData.priceMoney) amount = <div className='text-color6 fw-semibold'>+<NumericFormat value={option.modifierData.priceMoney.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></div>
            inputs.push(
                <div className='option' key={option.modifierData.name}>
                    <div className="row">
                        <div className="col-6">
                            {op}
                            <label htmlFor={option.id} className='text-color6 fw-semibold ms-2'>
                                {option.modifierData.name}
                            </label>
                        </div>
                        <div className="col-6 text-end">
                            {amount}
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className='modifier' key={modifier.details.name}>
                <div className="bg-color7 p-3 text-color6 poppins fw-semibold mt-3">
                    {modifier.details.name}
                </div>
                <div className='options px-3 pt-2'>
                    {inputs}
                </div>
            </div>
        )
    }

    variations(variations) {
        if (variations?.length > 1) {
            var inputs = [];
            variations?.forEach(variation => {
                inputs.push(
                    <div className='option' key={variation.itemVariationData.name}>
                        <div className="row">
                            <div className="col-6">
                                <input type='radio' id={variation.id} name={variation.itemVariationData.itemId} onChange={((e) => this.setVariation(e))} />
                                <label htmlFor={variation.id} className='text-color6 fw-semibold ms-2'>
                                    {variation.itemVariationData.name}
                                </label>
                            </div>
                            <div className="col-6 text-end">
                                <NumericFormat className='text-color6 fw-semibold' value={variation.itemVariationData.priceMoney.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} />
                            </div>
                        </div>
                    </div>
                );
            });
            return (
                <div className='modifier'>
                    <div className="bg-color7 p-3 text-color6 poppins fw-semibold mt-3">
                        Variations
                    </div>
                    <div className='options px-3 pt-2'>
                        {inputs}
                    </div>
                </div>
            )
        }
    }

    updateItem = () => this.props?.updateItem(this.state?.product_to_update);

    setVariation(e) {
        this.props?.product?.itemData?.variations?.forEach(variation => {
            if (variation.id === e.target.id)
                this.setState({ 
                    product_to_update:{
                        "uid": this.state?.product_to_update?.uid,
                        "variation": variation.id,
                        "quantity": this.state?.product_to_update?.quantity,
                        "modifiers": this.state?.product_to_update?.modifiers,
                        "modifiers_to_delete": this.state?.product_to_update?.modifiers_to_delete
                    },
                    total: variation?.itemVariationData?.priceMoney?.amount,
                    variations_required: false
                });
        });
    }

    setModifier(e, option, type) {
        var modifiers = [];
        this.state?.product_to_update?.modifiers?.forEach(m => {
            modifiers.push(m)
        })

        var modifiers_to_delete = [];
        this.state?.product_to_update?.modifiers_to_delete?.forEach(m => {
            modifiers_to_delete.push(m)
        })

        var modifiers_to_add = [];
        this.state?.product_to_update?.modifiers_to_add.forEach(m => {
            modifiers_to_add.push(m)
        })

        let amount = 0;
        if (option?.modifierData?.priceMoney) { amount = parseInt(option?.modifierData?.priceMoney?.amount) }
        
        if (e.target.checked){
            var mod = this.props?.item?.modifiers?.find(m => m.catalogObjectId === option?.id)
            if(mod){
                this.state?.product_to_update?.modifiers_to_delete.forEach(m => {
                    if(m !== mod.uid){
                        modifiers_to_delete.push(m);
                    }
                });
            }

            if (type === 'multiple'){
                modifiers_to_add.push({
                    "catalogObjectId": option?.id,
                    "modifierListId": option?.modifierData?.modifierListId,
                    "quantity": "1"
                });
            }
            else{
                if(modifiers_to_add.length === 0){
                    modifiers_to_add.push({
                        "catalogObjectId": option?.id,
                        "modifierListId": option?.modifierData?.modifierListId,
                        "quantity": "1"
                    });
                }
                else{
                    modifiers_to_add.forEach(modifier => {
                        if(modifier?.modifierListId === option?.modifierData?.modifierListId){
                            modifier = {
                                "catalogObjectId": option?.id,
                                "modifierListId": option?.modifierData?.modifierListId,
                                "quantity": "1"
                            }
                        }
                    });
                }
            }
        }
        else {
            modifiers.forEach((modifier, i) => {
                if (option.id === modifier.catalogObjectId){
                    modifiers.splice(i, 1);
                }    
            });
            amount *= (-1);
            modifiers_to_delete.push(this.props?.item?.modifiers?.find(m => m.catalogObjectId === option.id)?.uid)
        }
        amount += this.state?.modifiers_total;

        this.setState({
            product_to_update: {
                "uid": this.state?.product_to_update?.uid,
                "quantity": this.state?.product_to_update?.quantity,
                "modifiers": modifiers,
                "variation": this.state?.product_to_update?.variation,
                "modifiers_to_delete": modifiers_to_delete,
                "modifiers_to_add": modifiers_to_add
            },
            modifiers_total: amount
        });
    }

    setQuantity(quantity) {
        this.setState({
            product_to_update: {
                "uid": this.state?.product_to_update?.uid,
                "quantity": quantity,
                "modifiers": this.state?.product_to_update?.modifiers,
                "variation": this.state?.product_to_update?.variation,
                "modifiers_to_delete": this.state?.product_to_update?.modifiers_to_delete
            }
        });
    }

    setModifiersToDelete(modifiers){
        let modifiers_to_delete = [];
        if(modifiers) modifiers.forEach(modifier => modifiers_to_delete.push(modifier.uid))
        return modifiers_to_delete;
    }

}

export default CheckoutModal;
