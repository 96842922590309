import React from 'react';
import './assets/PickupDetails.css';
import validator from 'validator';

class PickupDetails extends React.Component {
    state = {
        pickup_details: {
            name: null,
            email: null,
            phone: null
        }
    }
    
    handleChange(event, value) {
        switch (value) {
            case 'name':
                this.setState({
                    pickup_details: {
                        name: event.target.value,
                        email: this.state?.pickup_details?.email,
                        phone: this.state?.pickup_details?.phone
                    }
                });
                break;
            case 'email':
                this.setState({
                    pickup_details: {
                        name: this.state?.pickup_details?.name,
                        email: event.target.value,
                        phone: this.state?.pickup_details?.phone
                    }
                });
                break;
            case 'phone':
                this.setState({
                    pickup_details: {
                        name: this.state?.pickup_details?.name,
                        email: this.state?.pickup_details?.email,
                        phone: event.target.value
                    }
                });
                break;
            default: break;
        }
    }

    handleSubmit(e) {
        this.props?.addPickupDetails(this.state);
      }
      render() {
        return (
            <div className="wrapper mb-2">
                <input className="w-100 my-2" type="text" value={this.props?.name} onChange={(e) => this.handleChange(e, 'name')} placeholder="Full name" required />
                {this.state?.pickup_details?.name !== null && !validator.isAlpha(this.state?.pickup_details?.name || '','en-US', {ignore: 's'}) && 
                    <div className="error">Name not valid.</div>
                }
                <input className="w-100 my-2" type="text" value={this.props?.email} onChange={(e) => this.handleChange(e, 'email')} placeholder="Email" />
                {this.state?.pickup_details?.email !== null && !validator.isEmail(this.state?.pickup_details?.email || '') && 
                    <div className="error">Email is not valid.</div>
                }
                <input className="w-100 my-2" type="text" value={this.props?.phone} onChange={(e) => this.handleChange(e, 'phone')} placeholder="Phone number" />
                {this.state?.pickup_details?.phone !== null && !validator.isMobilePhone(this.state?.pickup_details?.phone || '') && 
                    <div className="error">Phone number is not valid.</div>
                }
                <br /><br />
                <button className='w-100 btn bg-color4 text-color6' onClick={(e) => this.handleSubmit(e)} disabled={!(validator.isEmail(this.state?.pickup_details?.email || '') && validator.isMobilePhone(this.state?.pickup_details?.phone || '') && validator.isAlpha(this.state?.pickup_details?.name || '','en-US', {ignore: 's'}))}>Add Pickup Details</button>
            </div>
        );
    }
}

export default PickupDetails;