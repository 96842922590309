import React from 'react';
import { NumericFormat } from 'react-number-format';
import './assets/Cart.css';
import { Link } from 'react-router-dom';
import data from '../../../assets/resources';

class Cart extends React.Component {
    render() {
        if (this.props?.cart) {
            var items = [];
            var total = 0;
            var serviceChargeAndTaxes = 0;
            if (this.props?.cart?.order?.lineItems) {
                this.props?.cart?.order?.lineItems?.forEach(item => { items.push(this.item(item)) });
                total = this.props?.cart?.order?.totalMoney?.amount;
                serviceChargeAndTaxes = (parseFloat(this.props?.cart?.order?.totalServiceChargeMoney?.amount) + parseFloat(this.props?.cart?.order?.totalTaxMoney?.amount)) / 100;
            }

            var link;
            if (this.props.user) {
                if (this.props?.cart?.order?.fulfillments) {
                    link = (<Link to="/pay" className='btn bg-color4 poppins w-100 fw-semibold text-color6'>Proceed to checkout ${total / 100}</Link>);
                }
                else {
                    link = (<Link to="/checkout" className='btn bg-color4 poppins w-100 fw-semibold text-color6'>Proceed to checkout ${total / 100}</Link>);
                }
            } else {
                link = (<button type="button" className='btn bg-color4 poppins w-100 fw-semibold text-color6' data-bs-toggle="modal" data-bs-target="#authModal">
                    Proceed to checkout ${total / 100}
                </button>);
            }
            return (
                <div className="collapse position-absolute" id="collapseCart" hidden={this.props?.proced}>
                    <div className="d-flex justify-content-end">
                        <div className="text-end bg-color2 shadow-lg d-none d-md-block" style={{ width: "440px", marginLeft: "-350px", marginRight: "-90px" }}>
                            <button data-bs-toggle="collapse" data-bs-target="#collapseCart" className='btn text-color6'>
                                <img src={data.X} alt="" />
                            </button>
                            <div className='bg-color2 py-3' style={{ maxHeight: '330px', overflow: 'scroll' }}>
                                {items}
                                <div className='row px-4 mb-3'>
                                    <div className="col-7 text-start">
                                        <p className='text-color6'>
                                            <strong>Online Fee & Tax</strong><br />
                                        </p>
                                    </div>
                                    <div className="col-5">
                                        <p className='poppins text-color6'><NumericFormat value={serviceChargeAndTaxes} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></p>
                                    </div>
                                </div>
                            </div>
                            <div className="shadow-lg">
                                <div className='text-center bg-white p-2'>
                                    {link}
                                </div>
                            </div>
                        </div>
                        <div className="text-end bg-color2 shadow-lg d-md-none" style={{ width: "300px", marginLeft: "0", marginTop: "455px", zIndex: "1" }}>
                            <button className='btn text-color6' data-bs-toggle="collapse" data-bs-target="#collapseCart">
                                <img src={data.X} alt="" />
                            </button>
                            <div id="collapseCart" className='collapse bg-color2 py-3' style={{ maxHeight: '330px', overflowY: 'auto' }}>
                                {items}
                                <div className='row px-4 mb-3'>
                                    <div className="col-7 text-start">
                                        <p className='text-color6'>
                                            <strong>Online Fee & Tax</strong><br />
                                        </p>
                                    </div>
                                    <div className="col-5">
                                        <p className='poppins text-color6'>
                                            <NumericFormat
                                                value={serviceChargeAndTaxes}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="shadow-lg">
                                <div className='text-center bg-white p-2'>
                                    {link}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            );
        }

        else return (<div></div>)

    }

    item(item) {
        var modifiers = [];
        if (item?.modifiers)
            item?.modifiers.forEach(modifier => {
                if (modifier.basePriceMoney?.amount > 0) {
                    modifiers.push(<React.Fragment key={modifier.uid}><small className='fw-light'>{modifier.name} <strong><NumericFormat value={modifier.basePriceMoney?.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></strong></small><br /></React.Fragment>);
                } else {
                    modifiers.push(
                        <React.Fragment key={modifier.catalogObjectId}>
                            <small className='fw-light'>  {modifier.name}<br></br></small>
                        </React.Fragment>
                    )
                }
            });
        if (!item?.uid) {
            return null;
        }
        return (
            <div className='row px-4 mb-3' key={item?.uid}>
                <div className="col-6 text-start">
                    <p className='text-color6'>
                        <strong>{item?.name}</strong><br />
                        {item?.variationName && (<small className='fw-light'>{item?.variationName}</small>)} <br />
                        {modifiers}
                    </p>
                    <span className='quantity-button'>
                        <img onClick={(e) => this.setQuantity(item?.uid, (parseInt(item?.quantity) - 1))} src={data.ReduceCart} alt="" />
                    </span>
                    <span className='fw-semibold text-color6 mx-3'>{item?.quantity}</span>
                    <span className='quantity-button'>
                        <img onClick={(e) => this.setQuantity(item?.uid, (parseInt(item?.quantity) + 1))} src={data.AddCart} alt="" />
                    </span>
                </div>
                <div className="col-5">
                    <br />
                    {/* <small className='poppins text-color6'>Tax <strong><NumericFormat value={item?.totalTaxMoney?.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></strong></small> */}
                    <p className='poppins text-color6'><strong><NumericFormat value={(item.grossSalesMoney.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></strong></p>
                </div>
                <div className="col-1">
                    <button type="button" className="btn">
                        <img onClick={(e) => this.setQuantity(item?.uid, 0)} src={data.Trash} alt="" />
                    </button>
                </div>
            </div>
        )
    }

    setQuantity = (uid, quantity) => this.props?.setQuantity(uid, quantity);

}

export default Cart;
