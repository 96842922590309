import React from 'react';
import { NumericFormat } from 'react-number-format';
import './assets/TipsPicker.css';

class TipsPicker extends React.Component {
  state = {
    tip: 0,
    selectedButton: '15_percent', // Default selection
    customTipAmount: ''
  };

  // Predefined tip percentages
  tipOptions = [
    { id: '10_percent', label: '10%', value: 0.10 },
    { id: '15_percent', label: '15%', value: 0.15 },
    { id: '20_percent', label: '20%', value: 0.20 }
  ];

  handleButtonClick = (tip, selected) => {
    this.setState({ 
      tip, 
      selectedButton: selected,
      customTipAmount: selected === 'custom' ? tip/100 : '' 
    });
    this.props?.submitTip(Math.round(tip));
  };

  componentDidMount() {
    const defaultTip = this.props.cart?.totalMoney?.amount * 0.15;
    this.handleButtonClick(defaultTip, '15_percent');
  }

  renderTipButton = ({ id, label, value }) => {
    const { cart } = this.props;
    const { selectedButton } = this.state;
    const tipAmount = cart?.totalMoney?.amount * value;

    return (
      <div className="col-3" key={id}>
        <button
          className={`tip-button ${selectedButton === id ? 'selected' : ''}`}
          onClick={() => this.handleButtonClick(tipAmount, id)}
        >
          <span className="tip-percentage">{label}</span>
          <span className="tip-amount">
            <NumericFormat
              value={tipAmount / 100}
              displayType="text"
              thousandSeparator={true}
              prefix={'$'}
              fixedDecimalScale={true}
              decimalScale={2}
            />
          </span>
        </button>
      </div>
    );
  };

  render() {
    const { customTipAmount } = this.state;

    return (
      <div className="tips-picker">
        <h3 className="tips-title">Add gratuity</h3>
        <div className="row g-3">
          {this.tipOptions.map(this.renderTipButton)}
          <div className="col-3">
            <button
              className={`tip-button tip-amount ${this.state.selectedButton === 'custom' ? 'selected' : ''}`}
              data-bs-toggle="modal"
              data-bs-target="#customTipModal"
            >
              <span className="tip-percentage">Custom</span>
              {customTipAmount && (
                <span className="tip-amount">${customTipAmount}</span>
              )}
            </button>
          </div>
        </div>

        {/* Custom Tip Modal */}
        <div className="modal fade" id="customTipModal" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add a custom tip</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div className="modal-body">
                <div className="custom-tip-input">
                  <span className="currency-symbol">$</span>
                  <input
                    type="number"
                    placeholder="0.00"
                    step="0.01"
                    min="0"
                    ref={input => this.tipInput = input}
                    onClick={() => this.tipInput?.select()}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.handleButtonClick(this.tipInput.value * 100, 'custom')}
                  data-bs-dismiss="modal"
                >
                  Add Tip
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TipsPicker;
