import React from "react";
import "./assets/PaymentForm.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSafari } from "react-device-detect";
import withNavigation from '../../../withNavigation/withNavigation';

class PaymentForm extends React.Component {
  timerID;
  cashAppPay;
  googlePay;
  applePay;

  state = {
    appId:
      process.env.REACT_APP_PROD === "true"
        ? process.env.REACT_APP_APPLICATION_ID
        : process.env.REACT_APP_APPLICATION_ID_DEV,
    locationId: this.props?.location,
    edit: false,
    tip: 0,
    newCard: this.props?.cards?.length > 0 ? false : true,
    selectedCard: null
  };

  componentDidUpdate(prevState) {
    if (prevState.tip !== this.props.tip) {
      this.setState({ tip: this.props?.tip });
      this.handlePaymentMethods();
    }
  }

  render() {
    let _cards = [];
    this.props?.cards?.forEach(card => {
      _cards.push(<option key={"card_" + card.id} value={card.id}>{card?.card_brand + " - " + card?.exp_month + "/" + card?.exp_year + " - XXXX-XXXX-XXXX-" + card?.last_4}</option>)
    })

    return (
      <div className="payment-wrapper">
        {/* Contact Details Section */}
        <div className="mb-4">
          {this.contactDetails()}
        </div>

        {/* Delivery Notice */}
        {this.props?.cart?.fulfillments[0]?.deliveryDetails && (
          <div className="delivery-notice mb-4">
            <p className="poppins text-color6 mb-0" style={{ fontStyle: "italic" }}>
              <i className="fas fa-info-circle me-2"></i>
              The gratuity is directed to the delivery driver. According to
              DoorDash, in cases of non-tipped orders, there may be a potential
              for extended delivery times.
            </p>
          </div>
        )}

        <hr className="my-4" />

        {/* Express Payment Section */}
        {(this.props?.attributes?.pay_cashapp ||
          this.props?.attributes?.pay_gpay ||
          (isSafari && this.props?.attributes?.pay_apple_pay)) && (
            <div className="express-payment-section mb-5">
              <h3 className="poppins text-color6 mb-4">Express Payment</h3>
              <div className="payment-methods-grid">
                {/* Apple Pay */}
                {isSafari && this.props?.attributes?.pay_apple_pay && (
                  <div className="payment-method-item">
                    <form id="apple-pay-payment-form">
                      <div id="apple-pay-button"></div>
                    </form>
                    <div id="payment-status-container"></div>
                  </div>
                )}

                {/* Google Pay */}
                {this.props?.attributes?.pay_gpay && (
                  <div className="payment-method-item">
                    <form id="google-payment-form">
                      <div id="google-pay-button"></div>
                    </form>
                    <div id="google-payment-status-container"></div>
                  </div>
                )}

                {/* Cash App */}
                {this.props?.attributes?.pay_cashapp && (
                  <div className="payment-method-item">
                    <form id="cash-app-payment-form">
                      <div id="cash-app-pay"></div>
                    </form>
                    <div id="cash-app-payment-status-container"></div>
                  </div>
                )}
              </div>
            </div>
          )}

        {/* Credit Card Section */}
        <div className="credit-card-section p-4 rounded border">
          <div className="section-header mb-4">
            <h3 className="poppins text-color6 mb-2">Credit Card</h3>
            <small className="text-muted d-flex align-items-center">
              <i className="fas fa-lock me-2"></i>
              All transactions are secure and encrypted by Square Inc
            </small>
          </div>

          {/* Card Selection Tabs */}
          {(this.props.cards && this.props.cards?.length > 0) && (
            <div className="card-type-selector">
                <div className="selector-buttons">
                    <button 
                        onClick={() => this.setState({ newCard: false })}
                        className={`selector-btn ${!this.state.newCard ? 'active' : ''}`}
                    >
                        <i className="fas fa-credit-card"></i>
                        Saved Cards
                    </button>
                    <button 
                        onClick={() => this.setState({ newCard: true, selectedCard: null })}
                        className={`selector-btn ${this.state.newCard ? 'active' : ''}`}
                    >
                        <i className="fas fa-plus-circle"></i>
                        New Card
                    </button>
                </div>
            </div>
          )}

          {/* Payment Form */}
          <form id="payment-form">
            <div id="card-container" className="mb-3" hidden={!this.state?.newCard}></div>
            <select 
              onChange={(e) => this.selectCard(e)} 
              className="form-select mb-3" 
              hidden={this.state?.newCard}
            >
              <option disabled selected>Select a card</option>
              {_cards}
            </select>
            <button
              className="btn w-100 py-3 bg-color4 text-color6 poppins fw-semibold"
              id="card-button"
              type="button"
            >
              Pay with card
            </button>
          </form>
        </div>

        {/* Terms and Conditions */}
        <div className="terms-section text-center mt-4">
          <span className="poppins text-color6 small">
            By placing an order with {process.env.REACT_APP_NAME}, customers agree to the{' '}
            <a href="/terms-and-conditions">Terms and Conditions</a>
          </span>
        </div>

        {/* Toast Container */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          limit={2}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="colored"
        />
      </div>
    );
  }

  selectCard(e) {
    const selectedCard = this.props?.cards?.find(c => c.id === e.target.value);
    this.setState({ selectedCard });
  }

  contactDetails() {
    if (!this.props?.cart?.fulfillments) return null;

    const fulfillment = this.props?.cart?.fulfillments[0];
    const isPickup = fulfillment?.pickupDetails && !fulfillment?.deliveryDetails;
    const details = isPickup ? fulfillment.pickupDetails : fulfillment.deliveryDetails;
    const recipient = details?.recipient;

    if (isPickup) {
        const location = this.props?.locations?.find(loc => loc.id === this.props.location);
        const address = location?.address;

        return (
            <div className="contact-details-section">
                {/* Store Information */}
                <div className="store-info mb-4">
                    <h3 className="poppins text-color6 mb-3">Store Information</h3>
                    <div className="info-grid">
                        <div className="info-item">
                            <span className="label">Store:</span>
                            <span className="value">{location?.name}</span>
                        </div>
                        <div className="info-item">
                            <span className="label">Address:</span>
                            <span className="value">
                                {address?.address_line_1}, {address?.locality},
                                {' '}{address?.administrative_district_level_1} {address?.postal_code}
                            </span>
                        </div>
                    </div>
                </div>

                <hr className="divider" />

                {/* Customer Information */}
                <div className="customer-info">
                    <h3 className="poppins text-color6 mb-3">Customer Information</h3>
                    <div className="info-grid">
                        <div className="info-item">
                            <span className="label">Name:</span>
                            <span className="value">{recipient?.displayName}</span>
                        </div>
                        <div className="info-item">
                            <span className="label">Email:</span>
                            <span className="value">{recipient?.emailAddress}</span>
                        </div>
                        <div className="info-item">
                            <span className="label">Phone:</span>
                            <span className="value">{recipient?.phoneNumber}</span>
                        </div>
                        <div className="info-item">
                            <span className="label">Order Type:</span>
                            <span className="value">{details?.scheduleType}</span>
                        </div>
                        <div className="info-item">
                            <span className="label">Pickup At:</span>
                            <span className="value">
                                {new Date(details?.pickupAt).toLocaleString("en-US", {
                                    dateStyle: "medium",
                                    timeStyle: "short"
                                })}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Delivery Details
    return (
        <div className="contact-details-section">
            <div className="customer-info mb-4">
                <h3 className="poppins text-color6 mb-3">Customer Information</h3>
                <div className="info-grid">
                    <div className="info-item">
                        <span className="label">Name:</span>
                        <span className="value">{recipient?.displayName}</span>
                    </div>
                    <div className="info-item">
                        <span className="label">Email:</span>
                        <span className="value">{recipient?.emailAddress}</span>
                    </div>
                    <div className="info-item">
                        <span className="label">Phone:</span>
                        <span className="value">{recipient?.phoneNumber}</span>
                    </div>
                </div>
            </div>

            <hr className="divider" />

            <div className="delivery-info">
                <h3 className="poppins text-color6 mb-3">Delivery Information</h3>
                <div className="info-grid">
                    <div className="info-item">
                        <span className="label">Delivery by:</span>
                        <span className="value">{details?.courierProviderName}</span>
                    </div>
                    <div className="info-item">
                        <span className="label">Dropoff Address:</span>
                        <span className="value">
                            {recipient?.address?.addressLine1 || recipient?.address?.address_line_1},{' '}
                            {recipient?.address?.administrativeDistrictLevel1 || recipient?.address?.administrative_district_level_1},{' '}
                            {recipient?.address?.locality}
                        </span>
                    </div>
                    {details?.dropoffNotes && (
                        <div className="info-item">
                            <span className="label">Dropoff Notes:</span>
                            <span className="value">{details.dropoffNotes}</span>
                        </div>
                    )}
                    <div className="info-item">
                        <span className="label">Estimated Delivery:</span>
                        <span className="value">
                            {new Date(details?.deliverAt).toLocaleString("en-US", {
                                dateStyle: "medium",
                                timeStyle: "short"
                            })}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
  }

  displayPaymentResults(response, toastr) {
    if (
      response?.status &&
      (response?.status === "COMPLETED" || response?.status === "APPROVED")
    ) {
      toast.update(toastr, {
        render: "Payment succesful",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      // Clear the old localStorage data
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key !== 'jwt' && key !== 'squareId') {
          localStorage.removeItem(key);
        }
      }
      this.props.navigate(`/order/${this.props?.cart?.id}`);
    } else {
      let error_message;
      if (response?.errors) {
        if (response?.errors[0]?.code === "CVV_FAILURE") {
          error_message = "Incorrect CVV";
        } else if (
          response?.errors[0]?.code === "ADDRESS_VERIFICATION_FAILURE"
        ) {
          error_message = "Incorrect postal code";
        } else if (response?.errors[0]?.code === "INVALID_EXPIRATION") {
          error_message = "Incorrect expiration date";
        } else if (response?.errors[0]?.code === "GENERIC_DECLINE") {
          error_message = "Card declined";
        } else {
          error_message = "Card declined";
        }
      }
      toast.update(toastr, {
        render: error_message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }

  addPickupDetails = (state) => {
    this.props?.addPickupDetails(state);
    this.setState({ edit: false });
  };

  

  async componentDidMount() {
    if (!this.props?.cart?.lineItems) {
      window.location.reload(true);
    }

    if (this?.props?.cart?.tenders) {
      let shouldRedirect = false;
      for (const tender of this.props.cart.tenders) {
        if (
          tender.type === "WALLET" ||
          tender.cardDetails?.status === "CAPTURED"
        ) {
          shouldRedirect = true;
          break;
        }
      }
      if (shouldRedirect) {
        // Clear the old localStorage data
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key !== 'jwt' && key !== 'squareId') {
            localStorage.removeItem(key);
          }
        }
        this.props.navigate(`/order/${this.props?.cart?.id}`);
        return; // Return to prevent further execution
      }
    }

    // Load Square.js script asynchronously if it's not already loaded
    if (!window.Square) {
      const script = document.createElement("script");
      script.src =
        process.env.REACT_APP_PROD === "false"
          ? `https://sandbox.web.squarecdn.com/v1/square.js`
          : `https://web.squarecdn.com/v1/square.js`;

      script.defer = true;
      script.async = true;
      document.head.appendChild(script);

      // Wait for the script to be loaded before continuing
      await new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
      });

      // Check again if Square.js is loaded
      if (!window.Square) {
        throw new Error("Square.js failed to load properly");
      }
    }

    // Initialize payments
    const payments = window.Square.payments(this.state.appId, this.state.locationId);

    // Initialize card payments
    let card;
    try {
        card = await this.initializeCard(payments);
    } catch (e) {
        console.error("Initializing Card failed", e);
        return;
    }

    // Handle card button events
    const cardButton = document.getElementById("card-button");
    if (cardButton) {
        cardButton.addEventListener("click", async (event) => {
            event.preventDefault();
            cardButton.disabled = true;
            const toastr = toast.loading("Processing...", { autoClose: 5000 });
            let token;
            let squareId = null;
            if (!this.state?.selectedCard) token = await this.tokenize(card, toastr);
            else {
                token = this.state?.selectedCard?.id;
                squareId = this.state?.selectedCard?.customer_id;
            }
            try {
                await axios.post(
                    process.env.REACT_APP_API_URL + "payment/create",
                    {
                        orderId: this.props?.cart?.id,
                        locationId: this.props?.cart?.locationId,
                        token,
                        squareId,
                        tipAmount: this.props?.tip,
                    }
                )
                .then(response => {
                    console.log("Payment Response", response);
                    if (response.data?.errors) {
                        toast.update(toastr, {
                            render: response.data.errors[0]?.detail,
                            type: "error",
                            isLoading: false,
                            autoClose: 3000,  // Close after 3 sec
                        });
                        if (response.data.errors[0]?.detail === "The order is already paid.") {
                            setTimeout(() => {
                                this.props.navigate(`/order/${this.props?.cart?.id}`);
                            }, 2000);
                            cardButton.disabled = true;
                        } else {
                            cardButton.disabled = false;
                        }
                    } else {
                        this.displayPaymentResults(response.data, toastr);
                        cardButton.disabled = false;
                    }
                })
                .catch(error => {
                    // console.log("Errorsillo");
                    // console.log(error);
                    
                    // Show a toast for errors caught in the catch block
                    toast.update(toastr, {
                        render: error.response?.data?.errors[0].detail || 'Payment failed. Please try again.',
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,  // Close after 5 seconds
                    });
                    
                    // Re-enable the payment button after the error
                    cardButton.disabled = false;
                
                });        

            } catch (error) {
                if (error.response) {
                    // The request was made, but the server responded with an error
                    if (error?.response?.data?.errors) {
                        error?.response?.data?.errors.forEach((error) => {
                            // console.log(error.code);
                            // console.log(error.detail);
                            if (error.detail === "The order is already paid.") {
                                toast.update(toastr, {
                                    render: `${error.detail} Redirecting to Order Detail`,
                                    type: "error",
                                    isLoading: false,
                                    autoClose: 5000,
                                });
                                setTimeout(() => {
                                    this.props.navigate(`/order/${this.props?.cart?.id}`);
                                }, 3000);
                            } else if(error.code === 'CARD_EXPIRED'){
                                toast.update(toastr, {
                                    render: error.detail,
                                    type: "error",
                                    isLoading: false,
                                    autoClose: 5000,
                                });
                                cardButton.disabled = false;
                            }
                        });
                    }
                    // You can then use `errorMessage` and `errorCode` to display or handle the error in your component
                } else if (error.request) {
                    // The request was made, but there was no response
                    console.error("No Response:", error.request);
                } else {
                    // Something happened in setting up the request that triggered an error
                    console.error("Error:", error.code);
                }
                toast.update(toastr, { render: error.errors[0].detail, type: "error", isLoading: false, autoClose: 5000 });
            }
        });
    }

    // Initialize express payment methods
    await this.handlePaymentMethods(payments);
  }

  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach("#card-container");
    return card;
  }

  async tokenize(paymentMethod, toastr) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      toast.update(toastr, {
        render: "No card",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
    
  }

  /* CASH APP FUNCTIONS */
  buildPaymentRequest(payments) {
    const amount =
      (parseInt(this.props?.cart?.totalMoney.amount) +
        parseInt(this.props?.tip)) /
      100;
    const paymentRequest = payments.paymentRequest({
      countryCode: "US",
      currencyCode: "USD",
      total: {
        amount: amount.toString(),
        label: "Total",
      },
    });
    return paymentRequest;
  }

  async initializeCashApp(payments) {
    const paymentRequest = this.buildPaymentRequest(payments);
    const cashAppPay = await payments.cashAppPay(paymentRequest, {
      redirectURL: `${process.env.REACT_APP_WEBSITE}/order/${this.props?.cart?.id}`,
      referenceId: process.env.REACT_APP_NAME,
    });
    await cashAppPay.attach("#cash-app-pay");
    return cashAppPay;
  }

  /* GOOGLE FUNCTIONS */

  async initializeGooglePay(payments) {
    const paymentRequest = this.buildPaymentRequest(payments);
    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach("#google-pay-button", {
      buttonColor: "black",
      buttonType: "short",
    });
    return googlePay;
  }

  /* APPLE PAY FUNCTIONS */
  async initializeApplePay(payments) {
    const paymentRequest = this.buildPaymentRequest(payments);
    const applePay = await payments.applePay(paymentRequest);
    // Note: You do not need to `attach` applePay.
    return applePay;
  }

  async handlePaymentMethods(payments) {
    try {
        // Initialize Cash App Pay
        if (this.props?.attributes?.pay_cashapp) {
            try {
                this.cashAppPay = await this.initializeCashApp(payments);
                if (this.cashAppPay) {
                    this.cashAppPay.addEventListener("ontokenization", async (event) => {
                        const { tokenResult, error } = event.detail;
                        if (error) {
                            // developer handles error
                            console.log(error);
                        } else if (tokenResult.status === "OK") {
                            const toastr = toast;
                            // developer passes token to backend for use with CreatePayment
                            try {
                                await axios
                                    .post(process.env.REACT_APP_API_URL + "payment/create", {
                                        orderId: this.props?.cart?.id,
                                        locationId: this.props?.cart?.locationId,
                                        token: tokenResult.token,
                                        tipAmount: this.props?.tip,
                                    })
                                    .then((res) => {
                                        if (res.data?.errors) {
                                            toast.update(toastr, {
                                                render: res.data.errors[0]?.detail,
                                                type: "error",
                                                isLoading: false,
                                                autoClose: 5000,
                                            });
                                            if (
                                                res.data.errors[0]?.detail === "The order is already paid."
                                            ) {
                                                setTimeout(() => {
                                                    this.props.navigate(`/order/${this.props?.cart?.id}`);
                                                }, 2000);
                                            }
                                        } else {
                                            this.displayPaymentResults(res.data, toastr);
                                        }
                                    });
                            } catch (error) {
                                if (error?.response?.data?.errors) {
                                    error?.response?.data?.errors.forEach((error) => {
                                        // console.log(error.code);
                                        // console.log(error.detail);
                                        if (error.detail === "The order is already paid.") {
                                            toast.update(toastr, {
                                                render: error.detail,
                                                type: "error",
                                                isLoading: false,
                                                autoClose: 5000,
                                            });
                                            setTimeout(() => {
                                                this.props.navigate(`/order/${this.props?.cart?.id}`);
                                            }, 3000);
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            } catch (e) {
                console.error("Initializing Cash App Pay failed", e);
            }
        }

        // Initialize Google Pay
        if (this.props?.attributes?.pay_gpay) {
            try {
                this.googlePay = await this.initializeGooglePay(payments);
                if (this.googlePay) {
                    const googlePayButton = document.getElementById("google-pay-button");
                    if (googlePayButton) {
                        googlePayButton.addEventListener("click", async (event) => {
                            event.preventDefault();
                            await this.handleGooglePayClick(event);
                        });
                    }
                }
            } catch (e) {
                console.error("Initializing Google Pay failed", e);
            }
        }

        // Initialize Apple Pay - only on Safari
        if (isSafari && this.props?.attributes?.pay_apple_pay) {
            try {
                this.applePay = await this.initializeApplePay(payments);
                if (this.applePay) {
                    const applePayButton = document.getElementById("apple-pay-button");
                    if (applePayButton) {
                        applePayButton.addEventListener("click", async (event) => {
                            event.preventDefault();
                            await this.handleApplePayClick(event);
                        });
                    }
                }
            } catch (e) {
                console.error("Initializing Apple Pay failed", e);
            }
        }
    } catch (error) {
        console.error("Error in handlePaymentMethods:", error);
    }
  }

  // Add this new method to handle Google Pay clicks
  async handleGooglePayClick(event) {
    const toastr = toast;
    try {
        const token = await this.tokenize(this.googlePay, toastr);
        if (token) {
            await axios.post(process.env.REACT_APP_API_URL + "payment/create", {
                orderId: this.props?.cart?.id,
                locationId: this.props?.cart?.locationId,
                token,
                tipAmount: this.props?.tip,
            })
            .then((res) => {
                if (res.data?.errors) {
                    toast.update(toastr, {
                        render: res.data.errors[0]?.detail,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                    });
                    if (res.data.errors[0]?.detail === "The order is already paid.") {
                        setTimeout(() => {
                            this.props.navigate(`/order/${this.props?.cart?.id}`);
                        }, 2000);
                    }
                } else {
                    this.displayPaymentResults(res.data, toastr);
                }
            });
        }
    } catch (error) {
        console.error("Google Pay payment failed:", error);
        if (error?.response?.data?.errors) {
            error?.response?.data?.errors.forEach((error) => {
                if (error.detail === "The order is already paid.") {
                    toast.update(toastr, {
                        render: error.detail,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                    });
                    setTimeout(() => {
                        this.props.navigate(`/order/${this.props?.cart?.id}`);
                    }, 3000);
                }
            });
        }
    }
  }
}

export default withNavigation(PaymentForm);
