import React from "react";
import './assets/Locations.css';
import { Link } from 'react-router-dom';

const Locations = (props) => {
    const locations = props?.locations?.map(location =>
        <div className="col-12 col-md-6" key={location.id || location?.address?.locality}>
            <Link 
                to={"/menu"} 
                onClick={(e) => props.setLocation(location.id, true)}
                className="location-selector"
            >
                <button 
                    className="selector-btn bg-color4 text-color6 poppins"
                    type="button"
                >
                    <div className="selector-content">
                        <i className="fas fa-map-marker-alt location-icon"></i>
                        <div className="location-text">
                            <small className="text-opacity-75">Order from</small>
                            <span className="location-name">{location?.address?.locality}</span>
                        </div>
                    </div>
                    <i className="fas fa-arrow-right"></i>
                </button>
            </Link>
        </div>
    )

    return (
        <div className="locations-wrapper">
            <div className="locations-container">
                <div className="header-section">
                    <h2 className="locations-title poppins">
                        Place your order
                    </h2>
                    <p className="header-subtitle poppins">
                        Select a location to start ordering
                    </p>
                </div>
                <div className="row g-3">
                    {locations}
                </div>
            </div>
        </div>
    )
}

export default Locations;