import React from 'react';
import data from '../assets/resources';
import { Link } from 'react-router-dom';
import './assets/Contact.css';
import Contents from './components/contents/Contents';
import Buttons from './components/buttons/Buttons';
import ReactGA from "react-ga4";

class Contact extends React.Component{
    componentDidMount() {
        document.title = `Contact | ${process.env.REACT_APP_NAME}`;
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }
    render() {
        return (
            <div className="contact-page">
                <div className="contact-background">
                    <div className="logo-container">
                        <Link to='/'>
                            <img className="logo-image" src={data.corner_logo} alt="" />
                        </Link>
                    </div>
                    <table className='addresses-section accordion-table'>
                        <tbody>
                            <tr>
                                <td className="align-bottom">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <Contents locations={this.props.locations}/>
                                        <Buttons locations={this.props.locations}/>
                                        <div className='bg-color5'>
                                            <br/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2953.3159827767317!2d-83.65621136480668!3d42.25042424166931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883ca8dd77e0ae17%3A0x61a0953e706f6b7e!2s2866%20Washtenaw%20Ave%2C%20Ypsilanti%2C%20MI%2048197%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1606005530766!5m2!1ses-419!2smx"
                        width="100%" height="320" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false"
                        tabIndex="0" title="map">
                    </iframe>
                </div>
            </div>
        );
    }
}

export default Contact;