import React from 'react';
import './assets/css/LoadingScreen.css';


const LoadingScreen = ({ imageSource }) => {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <img src={imageSource} alt="Loading" className="img-fluid bouncing-image" />
      </div>
    );
  };
  
  export default LoadingScreen;