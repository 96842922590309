import React, { Component } from 'react';
import './assets/TimePicker.css';
import { Calendar } from 'primereact/calendar';

class TimePicker extends Component {
    today = new Date();
    threeWeeksFromNow = new Date();
    // This will come from STRAPI as an array of dates
    invalidDates = [this.today];

    constructor(props) {
        super(props);
        this.threeWeeksFromNow.setDate(this.today.getDate() + 21);
        this.state = {
            isMobile: false,
        };
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
        this.setState({ isMobile });
    };

    onDateChange = (e) => {
        this.props.onPickupTimeChange(e.value);
        // if (e.value instanceof Date && !isNaN(e)) {
        //     // Call the handlePickupTimeChange function with the valid time
            
        //   } else {
        //     // Handle the case when the selected time is invalid
        //     console.error('Invalid selected time:', e.value);
        //     // Show an error message or take appropriate action
        //   }
        // // this.props.onPickupTimeChange(e.value);
    };

    render() {
        const { onScheduleTypeChange, scheduleType, pickupAt } = this.props;
        const { isMobile } = this.state;

        return (
            <div className="time-picker-container">
                <span className="poppins text-color6">When do you want to pick this order up?</span>
                
                <div className="schedule-type-selector">
                    <div className="selector-buttons">
                        {!this.props.blockDelivery && (
                            <button 
                                onClick={() => onScheduleTypeChange('ASAP')}
                                className={`selector-btn ${scheduleType === 'ASAP' ? 'active' : ''}`}
                            >
                                <i className="fas fa-bolt"></i>
                                ASAP
                            </button>
                        )}
                        <button 
                            onClick={() => onScheduleTypeChange('SCHEDULED')}
                            className={`selector-btn ${scheduleType !== 'ASAP' ? 'active' : ''}`}
                        >
                            <i className="fas fa-calendar-alt"></i>
                            Schedule
                        </button>
                    </div>
                </div>

                {scheduleType !== 'ASAP' && (
                    <div className="calendar-section">
                        <span className="schedule-label">Select day and time</span>
                        <Calendar
                            value={new Date(pickupAt)}
                            onChange={this.onDateChange}
                            minDate={this.today}
                            maxDate={this.threeWeeksFromNow}
                            showTime
                            hourFormat="12"
                            touchUI={isMobile}
                            showIcon
                            readOnlyInput
                            stepMinute={5}
                            disabledDates={this.invalidDates}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default TimePicker;
