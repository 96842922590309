import React from 'react';
import './assets/CategoriesListHorizontal.css';

class CategoriesListHorizontal extends React.Component {
    render() {
        let categories = [];
        this.props?.catalog.forEach(category =>
            categories.push(
                <li className='poppins my-2 text-start ms-3' key={category?.categoryData?.name}>
                    <a href={"#" + category.id} className='text-color6 category-link'><strong>{category?.categoryData?.name}</strong></a>
                </li>
            )
        );
        return (
            <div className='category-list-horizontal-container'>
                <ul className='category-list-horizontal'>
                    {categories}
                </ul>
            </div>
        )
    }
}

export default CategoriesListHorizontal;
