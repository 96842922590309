// utils/loadGoogleMapsScript.js
let googleMapsScriptLoadingPromise = null;

export const loadGoogleMapsScript = () => {
  if (!googleMapsScriptLoadingPromise) {
    googleMapsScriptLoadingPromise = new Promise((resolve, reject) => {
      const scriptAlreadyAdded = Array.from(document.head.getElementsByTagName('script')).some(script => {
        return script.src.includes('maps.googleapis.com/maps/api/js');
      });

      if (!scriptAlreadyAdded) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
        script.async = true;
        script.onload = () => {
          resolve();
        };
        script.onerror = () => {
          reject(new Error("Google Maps script failed to load"));
        };
        document.head.appendChild(script);
      } else {
        resolve(); // Script already added, resolve immediately
      }
    });
  }
  return googleMapsScriptLoadingPromise;
};
