import React from "react";
import { NumericFormat } from 'react-number-format';
import './assets/Products.css';
//import Add from './../../assets/images/add.png';
import data from '../../../assets/resources';
import Modal from "../modal/Modal";

class Products extends React.Component {

    category(category) {
        let products = [];
        category?.items?.forEach(item => {
            let max_price = 0;
            let min_price = null;
            let price;
            item.itemData.variations.forEach(variation => {
                // Get default price in cents, if present
                const defaultPriceStr = variation?.itemVariationData?.priceMoney?.amount;
                const defaultPrice = defaultPriceStr !== null ? parseInt(defaultPriceStr) : null;
            
                // Initialize the location price to null
                let locationPrice = null;
            
                // Check for location-specific overrides, if available
                if (variation?.itemVariationData?.locationOverrides && variation?.itemVariationData?.locationOverrides.length > 0) {
                    const locationOverride = variation.itemVariationData.locationOverrides.find(
                        l => l.locationId === this.props.location
                    );
            
                    // If a location-specific price exists, use it
                    if (locationOverride && locationOverride?.priceMoney?.amount) {
                        locationPrice = parseInt(locationOverride?.priceMoney?.amount);
                    }
                }
            
                // Determine the price to use: location-specific price if available, otherwise the default price
                const priceToCompare = locationPrice !== null ? locationPrice : defaultPrice;
            
                // Debugging: Check if prices exist for all variations
                // if (item.itemData.name === 'Salsa') {
                //     console.log(variation.itemVariationData);
                //     console.log(`Processing variation: ${variation.itemVariationData.name}, Default Price: ${defaultPrice}, Location Price: ${locationPrice}, Price to Compare: ${priceToCompare}`);
                // }
            
                // Ensure price is valid before proceeding
                if (priceToCompare !== null) {
                    // Update max_price
                    if (priceToCompare > max_price) max_price = priceToCompare;
            
                    // Update min_price (initialize it first or find a smaller price)
                    if (min_price === null || priceToCompare < min_price) {
                        min_price = priceToCompare;
                    }
                } else {
                    console.log(`No price found for variation: ${variation.itemVariationData.name}`);
                }
            });
            
            if (max_price === min_price && min_price !== null) {
                price = (
                    <p className='text-dark item-info poppins'>
                        <NumericFormat
                            value={max_price / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                        />
                    </p>
                );
            } else if (min_price !== null && max_price !== null) {
                price = (
                    <p className='text-dark item-info poppins'>
                        <NumericFormat
                            value={min_price / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                        />
                        -
                        <NumericFormat
                            value={max_price / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                        />
                    </p>
                );
            } else {
                price = <p className="text-dark item-info poppins">Price not available</p>;
            }
            

            products.push(
                <div className="col-md-6 col-6" key={item.itemData.name}>
                    <div className="row bg-color3 m-3 rounded rounded-3 shadow" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={"#modal" + item.id}>
                        <div className="col-12 col-md-4">
                            <img src={item.itemData.imageDetails[0]?.url ? item.itemData.imageDetails[0]?.url : data.defaultLogo} className='img-fluid' alt="" />
                        </div>
                        <div className="col-12 col-md-8 pt-3">
                            <p className='text-color1 item-name poppins mt-1 mb-0'><strong>{item.itemData.name}</strong></p>
                            <small>{item?.itemData?.description}</small>
                            {price}
                        </div>
                    </div>
                    <Modal open={this.props?.open} product={item} cart={this.props?.cart} addItemToCart={this.addItemToCart} location={this.props?.location} />
                </div>
            );
        });
        return (
            <React.Fragment key={category?.categoryData?.name}>
                <h3 id={category.id} className='poppins text-color6 ms-2'><strong>{category?.categoryData?.name}</strong></h3>
                <div className="row">
                    {products}
                </div>
            </React.Fragment>
        );
    }

    render() {
        let categories = [];
        this.props?.catalog?.forEach(category => categories.push(this.category(category)));
        return (<React.Fragment>{categories}</React.Fragment>);
    }

    addItemToCart = (state) => this.props?.addItemToCart(state);

}

export default Products;
