import React from 'react';
import axios from 'axios';
import data from '../assets/resources';
import './assets/Shop.css';
// import CategoriesList from './components/categories-list/CategoriesList';
import Locations from './components/locations/Locations';
import Products from './components/products/Products';
import Cart from './components/cart/Cart';
import CategoriesListHorizontal from './components/categories-list-horizontal/CategoriesListHorizontal';
import ReactGA from "react-ga4";
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import loadingImage from './../assets/minys/images/loading.gif'
import { Link } from 'react-router-dom';
import validator from 'validator';
import withNavigation from '../withNavigation/withNavigation';

class Shop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: null,
            cartLength: 0,
            promotions: this?.props?.multimedia,
            createOrder: true,
            isLoggedIn: Boolean(localStorage.getItem('jwt')),
            email: "",
            password: "",
            errorMessage: ''
        };
    }

    componentDidMount() {
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        
        // Handle initial location and cart setup
        if (currentLocation) {
            this.setLocation(currentLocation);
            if (orderId) {
                this.getCart(orderId);
            } else {
                this.createOrderId(currentLocation);
            }
        }

        document.title = `Shop | ${process.env.REACT_APP_NAME}`;
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;
        const prevLocation = prevProps?.location;
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');

        // Handle location changes
        if (prevLocation && location && prevProps?.location !== location) {
            this.setLocation(location);
            localStorage.setItem('locationId', location);
            this.createOrderId(location);
            this.setState({ promotions: this?.props?.multimedia });
        } 
        
        // Handle missing cart
        if (!prevState.cart && orderId) {
            this.getCart(orderId);
        }

        // Handle missing location
        if (!prevState.location && currentLocation) {
            this.setLocation(currentLocation);
        }

        // Rest of your existing componentDidUpdate logic...
    }

    createOrderId = (location) => {
        const url = process.env.REACT_APP_API_URL + 'orders/';
        axios.post(url + 'create', {
            locationId: location,
            lineItems: [],
            calculationPhase: 'TOTAL_PHASE',
            state: 'OPEN'
        })
            .then(res => {
                if (res.data.name === 'Error') {
                    alert(res.data.message);
                } else {
                    localStorage.setItem('orderId', res?.data?.order?.id);
                    localStorage.setItem('orderType', (new URL(window.location.href))?.pathname === "/menu" ? "regular" : "catering");
                    this.setState({ cart: res?.data, cartLength: 0 });
                }
            }
            );
    }

    getCart = (orderId) => {
        if (orderId && orderId !== "undefined") {
            const url = process.env.REACT_APP_API_URL + 'orders/';
            axios.get(url + '?orderId=' + orderId).then(res => {
                if (!res?.data?.lineItems) {
                    let cart = { ...this.state?.cart }
                    cart.order = res?.data;
                    this.setState({ cart });
                } else if (res?.data?.tenders) {
                    const tenders = res?.data?.tenders;
                    if (tenders.length > 0) {
                        let shouldRedirect = false;
                        tenders.forEach((tender) => {
                            if (tender.type === 'CARD' && tender.cardDetails?.status === 'CAPTURED') {
                                shouldRedirect = true;
                            } else if (tender.type === 'WALLET') {
                                shouldRedirect = true;
                            }
                        });
                        if (shouldRedirect) {
                            // Clear the old localStorage data
                            for (let i = 0; i < localStorage.length; i++) {
                                const key = localStorage.key(i);
                                if (key !== 'jwt' && key !== 'squareId') {
                                    localStorage.removeItem(key);
                                }
                            }
                            if (this.props.navigate) {
                                this.props.navigate(`/order/${orderId}`);
                            } else {
                                console.error('navigate is not available in props');
                            }
                        } else {
                            let cart = { ...this.state?.cart }
                            cart.order = res?.data;
                            this.setState({ cart: cart, cartLength: Number(res?.data?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                        }
                    }
                } else if (res?.data?.fulfillments?.length > 0) {
                    this.props.navigate("/pay");
                }
                else {
                    let cart = { ...this.state?.cart }
                    cart.order = res?.data;
                    this.setState({ cart: cart, cartLength: Number(res?.data?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                }
            });
        }
        else {
            this.createOrderId(this.props.location);
        }
    }

    setLocation = (location, ignoreLoaded) => {
        localStorage.setItem('locationId', location);
        this.props.setLocation(location, ignoreLoaded);
    };

    // Helper function to safely calculate cart length
    calculateCartLength = (data) => {
        try {
            if (!data?.order?.lineItems) return 0;
            return data.order.lineItems.reduce((total, item) => {
                const quantity = Number(item.quantity);
                return total + (isNaN(quantity) ? 0 : quantity);
            }, 0);
        } catch (error) {
            console.error('Error calculating cart length:', error);
            return 0;
        }
    }

    addItemToCart = async (state) => {
        if (!state?.product_to_add) {
            console.error('No product to add');
            return;
        }

        const currentPath = (new URL(window.location.href))?.pathname;
        const currentOrderType = localStorage.getItem("orderType");
        const isValidPath = (currentPath === "/menu" && currentOrderType === "regular") || 
                           (currentPath === "/catering" && currentOrderType === "catering");

        if (isValidPath) {
            try {
                let cart = this.state?.cart || {};
                if (cart?.order) {
                    if (!cart.order.lineItems) {
                        cart.order.lineItems = [];
                    }
                    cart.order.lineItems.push(state.product_to_add);
                } else {
                    cart = { order: { lineItems: [state.product_to_add] } };
                }

                try {
                    const updateResponse = await axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update',
                        {
                            "orderId": cart?.order?.id,
                            "locationId": this.props.location,
                            "lineItems": cart?.order?.lineItems,
                            "calculationPhase": "TOTAL_PHASE",
                            "state": "OPEN"
                        }
                    );
                    
                    const cartLength = this.calculateCartLength(updateResponse?.data);
                    this.setState({
                        cart: updateResponse?.data,
                        cartLength
                    });
                } catch (error) {
                    if (error.response?.data?.body?.[0]?.message?.includes("Expected value to be of type 'string' but found 'undefined'")) {
                        const createResponse = await axios.post(process.env.REACT_APP_API_URL + 'orders/create', {
                            "locationId": this.props.location,
                            "lineItems": [state?.product_to_add],
                            "calculationPhase": "TOTAL_PHASE",
                            "state": "OPEN"
                        });

                        localStorage.setItem('orderId', createResponse?.data?.order?.id);
                        localStorage.setItem('orderType', currentPath === "/menu" ? "regular" : "catering");

                        const cartLength = this.calculateCartLength(createResponse?.data);
                        this.setState({
                            cart: createResponse?.data,
                            cartLength
                        });
                    } else {
                        throw error;
                    }
                }
            } catch (error) {
                console.error('Error handling cart operation:', error);
            }
        } else {
            var startNewCart = window.confirm(
                `You already have a ${currentOrderType} cart, do you like to start a new ${currentOrderType === "regular" ? "catering" : "regular"} cart?`
            );
            if (startNewCart) {
                try {
                    const createResponse = await axios.post(process.env.REACT_APP_API_URL + 'orders/create', {
                        "locationId": this.props.location,
                        "lineItems": [state?.product_to_add],
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    });

                    localStorage.setItem('orderId', createResponse?.data?.order?.id);
                    localStorage.setItem('orderType', currentPath === "/menu" ? "regular" : "catering");

                    const cartLength = this.calculateCartLength(createResponse?.data);
                    this.setState({
                        cart: createResponse?.data,
                        cartLength
                    });
                } catch (error) {
                    console.error('Error creating new cart:', error);
                }
            }
        }
    }

    setQuantity = (uid, quantity) => {
        if (!this.state?.cart?.order?.lineItems) return;

        this.state.cart.order.lineItems.forEach(item => {
            if (item.uid === uid) {
                item.quantity = quantity;
                if (quantity !== 0) {
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.order?.id,
                        "locationId": this.props.location,
                        "lineItems": this.state?.cart?.order?.lineItems,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    }).then(res => {
                        localStorage.setItem('orderId', res?.data?.order?.id);
                        const cartLength = this.calculateCartLength(res?.data);
                        this.setState({ cart: res?.data, cartLength });
                    });
                } else {
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.order?.id,
                        "deleteItem": [uid],
                        "locationId": this.props.location,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    }).then(res => {
                        localStorage.setItem('orderId', res?.data?.order?.id);
                        const cartLength = this.calculateCartLength(res?.data);
                        this.setState({ cart: res?.data, cartLength });
                    });
                }
            }
        });
    }

    handleSignOut = (e) => {
        e.preventDefault();
        localStorage.removeItem('jwt');
        localStorage.removeItem('squareId');
        this.setState({ isLoggedIn: false });
        window.location.reload();
    };

    proceedToCheckout = (e) => {
        e.preventDefault()
        if (this.props.navigate) {
            this.props.navigate(this.state?.cart?.order?.fulfillments ? "/pay" : "/checkout");
        } else {
            console.error('navigate is not available in props');
        }
    }
    register = (e) => {
        e.preventDefault()
        if (this.props.navigate) {
            this.props.navigate("/signup");
        } else {
            console.error('navigate is not available in props');
        }
    }
    login = (e) => {
        e.preventDefault();
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errorMessage: '',
        });
    };

    validateForm = () => {
        const { email, password } = this.state;
        const errors = [];

        if (!validator.isEmail(email)) {
            errors.push('Invalid email address');
        }

        if (!password) {
            errors.push('Passwords cannot be empty');
        }

        if (errors.length > 0) {
            this.setState({ errorMessage: errors.join(', ') });
            return false;
        }

        return true;
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        var button_modal = document.getElementById("close-modal-button");

        if (!this.validateForm()) {
            return;
        }

        const { email, password } = this.state;
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + 'customer/login', {
                email,
                password
            });
            localStorage.setItem("jwt", response?.data?.jwt);
            localStorage.setItem("squareId", response?.data?.user?.squareId);
            if (this.props.navigate) {
                button_modal.click();
                this.props.navigate(this.state?.cart?.order?.fulfillments ? "/pay" : "/checkout");
            } else {
                console.error('navigate is not available in props');
            }
        } catch (error) {
            console.error('Login failed:', error.response.data);
            this.setState({ errorMessage: error.response.data.message || 'Login failed' });
        }
    };

    render() {
        if (!this.props?.catalog?.length) {
            return (
                <LoadingScreen imageSource={loadingImage} />
            )
        }

        let promos = [];
        this.state?.promotions?.forEach((promo, index) => {
            promos.push(
                <div className={index === 0 ? "carousel-item active" : "carousel-item"} key={"promo_" + index}>
                    <img src={promo} className="d-block w-100" alt="Promo" />
                </div>
            )
        })

        return (
            <div className='shop bg-color2'>
                <div className='corner-logo'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className="header sticky-top d-none d-md-block">
                    <div className="row align-items-center">
                        {/* Desktop Location Dropdown */}
                        <div className="col-md-2 offset-md-8 p-2">
                            <div className="dropdown bg-color2 rounded-pill">
                                <button
                                    className="btn dropdown-toggle w-100 d-flex align-items-center justify-content-center"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img src={data?.LocationIcon} alt="Location Icon" />
                                    <strong className="text-color6 ms-2">
                                        {this?.props?.locations?.find(location => location.id === this?.props?.location)?.address?.locality || 'Location'}
                                    </strong>
                                </button>
                                <Locations locations={this?.props?.locations} setLocation={this?.setLocation} />
                            </div>
                        </div>

                        {/* Desktop Cart Button */}
                        <div className="col-md-1 p-2">
                            <button
                                className="btn bg-color2 rounded-pill position-relative w-100"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCart"
                                aria-expanded="false"
                                aria-controls="collapseCart"
                                disabled={this?.state?.cartLength <= 0}
                            >
                                <img src={data?.CartIcon} alt="Cart Icon" />
                                <span className="badge rounded-circle bg-color4 text-color6 position-absolute top-0 start-100 translate-middle">
                                    {this?.state?.cartLength}
                                </span>
                            </button>
                            <Cart cart={this?.state?.cart} user={this?.props?.user} setQuantity={this?.setQuantity} proced={this?.state?.cartLength <= 0} />
                        </div>

                        {/* Desktop Profile Dropdown */}
                        <div className="col-md-1 p-2">
                            <div className="dropdown bg-color2 rounded-pill">
                                <button
                                    className="btn dropdown-toggle w-100"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                    </svg>
                                </button>
                                <ul className="dropdown-menu bg-color2">
                                    {!this?.props?.user ? (
                                        <>
                                            <li className="px-3 py-1 text-color3 poppins">
                                                <a className="text-decoration-none" href="/login">Login</a>
                                            </li>
                                            <li className="px-3 py-1 text-color3 poppins">
                                                <a className="text-decoration-none" href="/signup">Register</a>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li className="px-3 py-1 text-color3 poppins">
                                                <a className="text-decoration-none" href="/profile">Profile</a>
                                            </li>
                                            <li className="px-3 py-1 text-color3 poppins">
                                                <a className="text-decoration-none" href="/" onClick={this.handleSignOut}>Sign out</a>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="sticky-top d-md-none">
                    <div className="header">
                        <div className="row">
                            {/* Mobile Location Dropdown */}
                            <div className="col-4 p-2 d-flex justify-content-center align-items-center">
                                <div className="dropdown bg-color2 rounded-pill">
                                    <button className="btn dropdown-toggle d-flex align-items-center justify-content-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={data?.LocationIcon} alt="Location Icon" className="icon-size me-2" />
                                        <strong className="text-color6">
                                            {this?.props?.locations?.find(location => location.id === this?.props?.location)?.address?.locality || 'Location'}
                                        </strong>
                                    </button>
                                    <Locations locations={this?.props?.locations} setLocation={this?.setLocation} />
                                </div>
                            </div>


                            {/* Mobile Cart Button */}
                            <div className="col-4 p-2 d-flex justify-content-center align-items-center">
                                <button
                                    className="btn bg-color2 rounded-pill position-relative w-100"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseCart"
                                    aria-expanded="false"
                                    aria-controls="collapseCart"
                                    disabled={this?.state?.cartLength <= 0}
                                >
                                    <img src={data?.CartIcon} alt="Cart Icon" className="icon-size" />
                                    <span className="badge rounded-circle bg-color4 text-color6 position-absolute top-0 start-100 translate-middle">
                                        {this?.state?.cartLength}
                                    </span>
                                </button>
                                <Cart cart={this?.state?.cart} user={this?.props?.user} setQuantity={this?.setQuantity} proced={this?.state?.cartLength <= 0} />
                            </div>


                            {/* Mobile Profile Dropdown */}
                            <div className="col-4 p-2 d-flex justify-content-center align-items-center">
                                <div className="dropdown bg-color2 rounded-pill d-flex justify-content-center align-items-center">
                                    <button className="btn dropdown-toggle d-flex align-items-center justify-content-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person">
                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                        </svg>
                                    </button>
                                    <ul className="dropdown-menu bg-color2">
                                        {!this?.props?.user ? (
                                            <>
                                                <li className="px-3 py-1 text-color3 poppins">
                                                    <a className="text-decoration-none" href="/login">Login</a>
                                                </li>
                                                <li className="px-3 py-1 text-color3 poppins">
                                                    <a className="text-decoration-none" href="/signup">Register</a>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li className="px-3 py-1 text-color3 poppins">
                                                    <a className="text-decoration-none" href="/profile">Profile</a>
                                                </li>
                                                <li className="px-3 py-1 text-color3 poppins">
                                                    <a className="text-decoration-none" href="/" onClick={this.handleSignOut}>Sign out</a>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* CATEGORIES */}
                    <div className="col-12 p-2 sticky-categories">
                        <CategoriesListHorizontal catalog={this?.props?.catalog} />
                    </div>
                </div>




                <div className="bg-color2 pt-5">
                    {/*<div className='shop-image d-none d-md-block'></div>*/}
                    <div className="carousel-wrapper">
                        <div className="carousel" data-ride="carousel" data-interval="5000">
                            <div className="carousel-inner">
                                {promos}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target=".carousel" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target=".carousel" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>

                    {this.props?.locations?.map(loc => 
                        loc.id === this.props.location && (
                            <div className="menu-header-wrapper" key={loc.id}>
                                <div className="menu-header">
                                    <i className="fas fa-utensils"></i>
                                    <h2 className="poppins text-color6 mb-0">
                                        Ordering from {loc.name}
                                    </h2>
                                    <i className="fas fa-pepper-hot"></i>
                                </div>
                            </div>
                        )
                    )}

                    <div className="row">
                        <Link to="/" className='poppins text-color6 fst-italic text-end' onClick={(e) => {
                            // Clear the old localStorage data
                            for (let i = 0; i < localStorage.length; i++) {
                                const key = localStorage.key(i);
                                if (key !== 'jwt' && key !== 'squareId' && key !== 'locationId') {
                                    localStorage.removeItem(key);
                                }
                            }
                        }} >
                            <img src={data.Back} alt={'Back to home'} />
                            <small>Start new order</small>
                        </Link>
                        {
                            (new URL(window.location.href))?.pathname === "/menu" ?
                                <React.Fragment>
                                    <div className="col-12 p-2 d-none d-md-block sticky-categories-desktop">
                                        <CategoriesListHorizontal catalog={this?.props?.catalog} />
                                    </div>
                                    <div className="col-md-10 col-12 p-5">
                                        <Products location={this.props.location} open={this.props.open} catalog={this.props.catalog} cart={this.state.cart} addItemToCart={this.addItemToCart} />
                                    </div>
                                </React.Fragment> :
                                <React.Fragment>
                                    <div className="col-md-12 col-12 p-5">
                                        <Products location={this.props.location} open={this.props.open} catalog={this.props.catalog} cart={this.state.cart} addItemToCart={this.addItemToCart} />
                                    </div>
                                </React.Fragment>
                        }

                    </div>
                </div>
                <div className="modal fade" id="authModal" tabIndex="-1" aria-labelledby="authModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body bg-color3 rounded text-center">
                                <img className='img-fluid my-5 mx-auto' src={data.logo_login} alt="Miny's Mexican Restaurant" />
                                <form onSubmit={this.handleSubmit} className='p-5'>
                                    <p className='text-danger'>{this.state.errorMessage}</p>
                                    <div className='form-group m-2'>
                                        <label className='text-color1 fw-bold'>Email:</label><br />
                                        <input
                                            type="email"
                                            className="w-100 my-2 bg-color2"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            required
                                        />
                                        {!validator.isEmail(this.state.email) && (
                                            <div className="error">Invalid email address</div>
                                        )}
                                    </div>
                                    <br />

                                    <div className='form-group m-2'>
                                        <label className='text-color1 fw-bold'>Password:</label><br />
                                        <input
                                            type="password"
                                            className="w-100 my-2 bg-color2"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                    <br />

                                    <button id='close-modal-button' data-bs-dismiss="modal" hidden>Close modal</button>
                                    <button onClick={e => this.handleSubmit(e)} className='btn bg-color4 poppins w-100 fw-semibold text-color6 mb-3'>Sign in</button>
                                    <button data-bs-dismiss="modal" onClick={e => this.proceedToCheckout(e)} className='btn bg-color4 poppins w-100 fw-semibold text-color6 mb-3'>Continue as a guest</button>
                                    <button data-bs-dismiss="modal" onClick={e => this.register(e)} className='btn bg-color4 poppins w-100 fw-semibold text-color6 mb-3'>Sign up</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withNavigation(Shop);
