import React from 'react';
import data from '../assets/resources';
import ReactGA from "react-ga4";

class PrivacyPolicy extends React.Component {
    componentDidMount() {
        document.title = `Privacy | ${process.env.REACT_APP_NAME}`;
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }
    
    render() {
        return (
            <div className="bg-color2 pb-5">
                <div className='corner-logo'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className="container pt-5">
                    <h2 className='poppins text-color6 mt-5 text-center fw-bold'>Privacy Policy</h2>

                    <p className='poppins text-color6 mt-5'>
                        {process.env.REACT_APP_NAME} (“we”, “us”, “our”) respects the privacy of our customers
                        and visitors to our website. This Privacy Policy explains how we collect, use, disclose, and
                        protect information that we receive from you in connection with the website or services
                        (“Services”). This Privacy Policy applies to information collected through our website, located at
                        &nbsp;{process.env.REACT_APP_WEBSITE} (the “Website”).
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>Collection of Information:</span> <br />
                        We collect the following types of information from you when you visit our Website, use our Services, or provide us with information:
                    </p>
                    
                    <ul className='poppins text-color6 mt-5'>
                        <li>Personal Information: This is information that identifies you as an individual and includes your name, email address, phone number, and other contact information.</li>
                        <li>Payment Information: This is information related to your credit or debit card or other payment method that you use to purchase our Services.</li>
                        <li>Location Information: We may collect information about your physical location when you use our Services.</li>
                        <li>Usage Information: This is information about how you use our Services, such as the pages you visit, the content you view, and the features you use.</li>
                        <li>Device Information: We may collect information about the device you use to access our Services, including the device type and operating system, IP address, and other device identifiers.</li>
                    </ul>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>Use of Information:</span> <br />
                        We use the information we collect to provide, maintain, and improve our Services, as well as to contact you about our products and services. Specifically, we may use your information to:
                    </p>
                    
                    <ul className='poppins text-color6'>
                        <li>Process and deliver orders, payments, and other services;</li>
                        <li>Respond to your inquiries and provide customer service;</li>
                        <li>Communicate with you about our Services;</li>
                        <li>Monitor and analyze trends and usage;</li>
                        <li>Improve our Services;</li>
                        <li>Pre-fill forms;</li>
                        <li>Provide personalized content;</li>
                        <li>Offer tailored advertising and marketing messages;</li>
                        <li>Protect against fraud and other misuse of our Services;</li>
                        <li>Comply with applicable laws and regulations.</li>
                    </ul>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>Disclosure of Information:</span> <br />
                        We may share your information with third parties in the following circumstances:
                    </p>

                    <ul className='poppins text-color6'>
                        <li>With your consent;</li>
                        <li>To our third-party service providers and partners;</li>
                        <li>To comply with legal or regulatory obligations;</li>
                        <li>To protect and defend our rights and property;</li>
                        <li>To prevent or investigate potential fraud or other misuse of our Services;</li>
                        <li>In connection with a sale, merger, or other transfer of our business.</li>
                    </ul>
                    
                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>Security of Information:</span> <br />
                        We use reasonable administrative, technical, and physical security measures to protect your information from unauthorized access, use, or disclosure. We also use secure server technology to protect your information from unauthorized access.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>Data Retention:</span> <br />
                        We retain your information for as long as is necessary to provide you with our Services. We may also retain your information as necessary to comply with legal obligations, resolve disputes, and enforce our agreements.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>Changes to this Privacy Policy:</span> <br />
                        We may update this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we will notify you by posting a notice on our Website or sending you an email. We encourage you to periodically review this page for the latest information on our privacy practices.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>Contact Us:</span> <br />
                        If you have any questions or concerns about this Privacy Policy, please contact us at {process.env.REACT_APP_INFO_EMAIL}
                    </p>
                </div>
            </div>
        )
    }
}

export default PrivacyPolicy;