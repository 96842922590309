import React from 'react';
import axios from 'axios';
import data from '../assets/resources';
import './assets/Order.css';
import StepProgressBarPickup from './components/progress-bar-pickup/ProgressBarPickup';
import StepProgressBarDelivery from './components/progress-bar-delivery/ProgressBarDelivery';
import CartDescription from '../Shared/cart-description/CartDescription';
import { Link } from 'react-router-dom';
import Pusher from 'pusher-js';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import loadingImage from './../assets/minys/images/loading.gif'

class Order extends React.Component {

  state = {
    order: null,
    cartLength: 0,
    delivery: null
  }
  componentDidMount() {
    document.title = `Order | ${process.env.REACT_APP_NAME}`;
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
      cluster: 'us2',
      encrypted: true
    });
    const channel = pusher.subscribe('delivery');
    channel.bind('update', data => {
      if (this.state.order.fulfillments[0].deliveryDetails.squareDeliveryId === data.message.data.id) {
        this.setState({
          delivery: data.message.data
        });
      }
    });
  }
  clearStorage() {
    // Collect keys to remove
    const keysToRemove = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== 'jwt' && key !== 'squareId' && key !== 'locationId') {
        keysToRemove.push(key);
      }
    }

    // Remove the collected keys
    keysToRemove.forEach(key => {
      localStorage.removeItem(key);
    });
  }
  handleClick = (e) => {
    e.preventDefault(); // Prevent the default navigation
    this.clearStorage(); // Clear localStorage

    // Use a timeout to navigate after clearing localStorage
    setTimeout(() => {
      window.location.href = '/'; // Navigate to the home page
    }, 0);
  };



  render() {
    if (!this.state?.order) {
      this.getOrder();
      return (
        <LoadingScreen imageSource={loadingImage} />
      )
    }
    else {
      const statusTextMap = {
        created: 'Delivery created',
        enroute_to_pickup: 'Picking up',
        arrived_at_pickup: 'Arrived to Pick up',
        picked_up: 'Picked Up',
        arrived_at_dropoff: 'Arrived at Dropoff',
        delivered: 'Delivered',
        confirmed: 'New Dasher Confirmed',
        cancelled: 'Cancelled'
      };

      const deliveryStatus = this.state.delivery?.delivery_status;
      const statusText = statusTextMap[deliveryStatus] || this.state.delivery?.delivery_status;
      const location = this.props?.locations.find(location => location.id === this.state?.order?.locationId);
      let PaymentMethod;

      const capturedTender = this.state?.order?.tenders.find(tender => tender.type === 'WALLET' || (tender.cardDetails && tender.cardDetails.status === 'CAPTURED') || (tender.cardDetails && tender.cardDetails.status === 'AUTHORIZED'));
      const cardDetailsStatus = capturedTender?.cardDetails?.status;

      if (capturedTender) {
        if (capturedTender.type === 'WALLET') {
          PaymentMethod = (
            <p className='poppins text-color6 fw-semibold mt-3'>
              {capturedTender.type}<br />
              Paid: ${(capturedTender.amountMoney.amount) / 100}<br />
              Status: {cardDetailsStatus}
            </p>
          );
        } else {
          PaymentMethod = (
            <p className='poppins text-color6 fw-semibold mt-3'>
              {capturedTender.type}<br />
              {capturedTender.cardDetails.card.cardBrand}<br />
              XXXX-XXXX-XXXX-{capturedTender.cardDetails.card.last4}<br />
              Paid: ${(capturedTender.amountMoney.amount) / 100}<br />
              Status: {cardDetailsStatus}
            </p>
          );
        }
      } else {
        PaymentMethod = (
          <p className='poppins text-color6 fw-semibold mt-3'>
            Payment not found
          </p>
        );
      }

      // Helper function to render stars
      // const renderStars = (rating) => {
      //     const starCount = Math.round(rating);
      //     const filledStars = '★'.repeat(starCount);
      //     const emptyStars = '☆'.repeat(5 - starCount);
      //     return filledStars + emptyStars;
      // };

      const fulfillment = this.state?.order?.fulfillments[0];
      const recipient = {
        name: fulfillment?.deliveryDetails?.recipient?.displayName || fulfillment?.pickupDetails?.recipient?.displayName,
        email: fulfillment?.deliveryDetails?.recipient?.emailAddress || fulfillment?.pickupDetails?.recipient?.emailAddress,
        phone: fulfillment?.deliveryDetails?.recipient?.phoneNumber || fulfillment?.pickupDetails?.recipient?.phoneNumber
      };

      return (
        <div className='shop bg-color2'>
          {/* Corner Logo */}
          <div className='corner-logo position-absolute d-none d-md-block'>
            <a href="/">
              <img className='img-fluid' src={data.corner_logo} alt="Miny's Logo" />
            </a>
          </div>

          {/* Store Information */}
          <div className="container pt-4">
            <div className="row">
              <div className="col-12 text-center mb-4">
                <h1 className='poppins fw-bold text-color6'>{process.env.REACT_APP_NAME}</h1>
                {location && (
                  <p className='poppins text-color6'>
                    {location?.address?.address_line_1}, {location?.address?.locality}
                  </p>
                )}
              </div>
            </div>

            {/* Navigation Links */}
            <div className="row mb-4">
              <div className="col-12 text-end">
                <Link
                  to="/"
                  className='poppins text-color6 fst-italic'
                  onClick={this.handleClick}
                >
                  <img src={data.Back} alt='Back to home' className="me-2" />
                  <small>Start new order</small>
                </Link>
              </div>
            </div>

            {/* Order Details */}
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2 text-center mb-5">
                <h2 className='poppins fw-bold text-color6'>
                  Order Details for {this.state.order.fulfillments[0]?.pickupDetails?.recipient?.displayName}
                </h2>
                <br />
                {/* Progress Bar */}
                {this.state?.delivery ? 
                  <StepProgressBarDelivery fulfillments={this.state.order.fulfillments[0]} delivery={this.state.delivery} /> : 
                  <StepProgressBarPickup fulfillments={this.state.order.fulfillments[0]} />
                }
              </div>
            </div>

            {/* Order Information Grid */}
            <div className="row g-4 mb-5">
              {/* Cart Description */}
              <div className="col-12 col-md-6">
                <div className="card bg-color2 shadow-sm h-100">
                  <div className="card-body">
                    <h4 className='poppins text-color6 fw-bold mb-4'>Cart Description</h4>
                    <CartDescription 
                      tip={this.state?.order?.tenders[0]?.tipMoney?.amount ?? this.state?.order?.totalTipMoney?.amount} 
                      edit={false} 
                      items={this.state?.order} 
                      catalog={this.props?.catalog} 
                    />
                  </div>
                </div>
              </div>

              {/* Customer & Payment Info */}
              <div className="col-12 col-md-6">
                <div className="card bg-color2 shadow-sm h-100">
                  <div className="card-body">
                    {/* Customer Info */}
                    <h4 className='poppins text-color6 fw-bold mb-3'>Customer Information</h4>
                    <p className='poppins text-color6 fw-semibold'>
                      {recipient?.name}<br />
                      {recipient?.email}<br />
                      {recipient?.phone}
                    </p>

                    <hr className="my-4" />

                    {/* Payment Info */}
                    <h4 className='poppins text-color6 fw-bold mb-3'>Payment Method</h4>
                    {PaymentMethod}
                  </div>
                </div>
              </div>
            </div>

            {/* Pickup or Delivery Information */}
            {this.state?.order?.fulfillments[0]?.pickupDetails && !this.state?.order?.fulfillments[0]?.deliveryDetails && (
              <div className="card bg-color2 shadow-sm mb-5">
                <div className="card-body">
                  <h4 className='poppins text-color6 fw-bold mb-3'>Pickup Location</h4>
                  <p className='poppins text-color6 mb-2'>Pickup at {location?.address?.locality}</p>
                  <a 
                    className='poppins text-color6' 
                    rel="noreferrer" 
                    target='_blank' 
                    href={`https://maps.google.com/?q=${location?.address?.address_line_1},${location?.address?.locality},${location?.address?.administrative_district_level_1},${location?.address?.postal_code}`}
                  >
                    {location?.address?.address_line_1}, {location?.address?.locality}, {location?.address?.administrative_district_level_1}, {location?.address?.postal_code}
                  </a>
                </div>
              </div>
            )}
            {/* Delivery Information */}
            {this.state?.delivery && (
              <div className="card bg-color2 shadow-sm mb-5">
                <div className="card-body">
                  <h4 className='poppins text-color6 fw-bold mb-4'>Delivery Information</h4>
                  <div className="row g-4">
                    {/* Dasher Information */}
                    {this.state.delivery?.dasher_name && (
                      <div className="col-md-4">
                        <h5 className="poppins text-color6 fw-bold mb-3">Dasher Information</h5>
                        <p className="poppins text-color6 mb-2">
                          <strong>Name:</strong> {this.state.delivery?.dasher_name}
                        </p>
                        {statusText !== 'Delivered' && (
                          <p className="poppins text-color6 mb-2">
                            <strong>Contact:</strong>{' '}
                            <a href={`tel:${this.state.delivery?.dasher_dropoff_phone_number}`}>
                              {this.state.delivery?.dasher_dropoff_phone_number}
                            </a>
                          </p>
                        )}
                        {statusText === 'Delivered' && (
                          <div>
                            <p className="poppins text-color6 fw-bold mb-2">Proof Of Delivery:</p>
                            <img 
                              className='img-fluid rounded' 
                              src={this.state.delivery?.dropoff_verification_image_url} 
                              alt='Proof Of Delivery' 
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {/* Delivery Status */}
                    <div className="col-md-4">
                      <h5 className='poppins text-color6 fw-bold mb-3'>Status Information</h5>
                      <p className="poppins text-color6 mb-2">
                        <strong>Status:</strong> {statusText}
                      </p>
                      <p className="poppins text-color6 mb-2">
                        <strong>Drop Off ETA:</strong>{' '}
                        {this.state?.delivery?.dropoff_time_estimated && 
                          new Date(this.state?.delivery?.dropoff_time_estimated)
                            .toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}
                      </p>
                    </div>

                    {/* Tracking Button */}
                    {this.state.delivery.status !== 'delivered' && (
                      <div className="col-md-4">
                        <h5 className='poppins text-color6 fw-bold mb-3'>Track Order</h5>
                        <button 
                          className="tracking-btn bg-color4 text-color6 poppins w-100" 
                          onClick={() => window.open(this.state?.delivery?.tracking_url, '_blank')}
                        >
                          <i className="fas fa-map-marker-alt me-2"></i>
                          Track Delivery
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {this.state?.order?.fulfillments[0]?.deliveryDetails && !this.state?.delivery && (
              <div className="card bg-color2 shadow-sm mb-5">
                <div className="card-body">
                  <h4 className='poppins text-color6 fw-bold mb-4'>Delivery Information</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="poppins text-color6 mb-2">
                        Name: {this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.displayName}
                      </p>
                      <p className="poppins text-color6 mb-2">
                        Address: {this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.address?.addressLine1}
                        {this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.address?.addressLine2 && `, ${this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.address?.addressLine2}`}, {' '}
                        {this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.address?.locality}, {' '}
                        {this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.address?.administrativeDistrictLevel1} {' '}
                        {this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.address?.postalCode}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <h5 className='poppins text-color6 fw-bold mb-3'>Contact Information</h5>
                      <p className="poppins text-color6 mb-2">
                        <strong>Phone:</strong> {this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.phoneNumber}
                      </p>
                      {this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.email && (
                        <p className="poppins text-color6 mb-2">
                          <strong>Email:</strong> {this.state?.order?.fulfillments[0]?.deliveryDetails?.recipient?.email}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );

    }
  }

  async getOrder() {
    const url = process.env.REACT_APP_API_URL + 'orders/';
    const deliveryOrderId = await axios.get(url + '?orderId=' + this.props?.orderId)
      .then(res => {
        let order = { ...this.state?.order };
        order = res?.data;
        if (!res?.data?.lineItems) {
          this.setState({ order });
          return null;
        }
        this.setState({ order, cartLength: Number(res?.data?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
        if (order?.fulfillments[0]?.deliveryDetails) {
          return order?.fulfillments[0]?.deliveryDetails?.squareDeliveryId;
        }
        return null;
      });
    if (deliveryOrderId) {
      const deliveryRes = await axios.post(`${process.env.REACT_APP_API_URL}delivery/get-doordash-delivery`, {
        'deliveryId': deliveryOrderId
      });
      this.setState({
        delivery: deliveryRes.data.data
      });
    }
  }
}

export default Order;
