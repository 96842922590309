import React from 'react';
import data from '../assets/resources';
import ReactGA from "react-ga4";

class TermsAndConditions extends React.Component{
    componentDidMount() {
        document.title = `Terms | ${process.env.REACT_APP_NAME}`;
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }

    render() {
        return(
            <div className="bg-color2 pb-5">
                <div className='corner-logo'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className="container pt-5">
                    <h2 className='poppins text-color6 mt-5 text-center fw-bold'>{process.env.REACT_APP_NAME} Terms and Conditions</h2>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>1. Acceptance of Terms</span> <br />
                        {process.env.REACT_APP_NAME} (“we”, “us”, “our”) provides its services to you through its website located at {process.env.REACT_APP_WEBSITE} (the “Site”) subject to the following Terms and Conditions (“Terms”). By accessing, browsing, and using the Site, you accept, without limitation or qualification, the Terms and acknowledge that any other agreements between you and {process.env.REACT_APP_NAME} are superseded and of no force or effect.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>2. Modifications</span> <br />
                        {process.env.REACT_APP_NAME} reserves the right to change these Terms, the Site, and any products or services mentioned on the Site at any time without notice. Any changes to these Terms will be effective immediately upon posting to the Site. By continuing to use the Site, you agree to accept such changes.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>3. Use of Site</span> <br />
                        You may use the Site only for your own personal, non-commercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from the Site.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>4. Privacy</span> <br />
                        {process.env.REACT_APP_NAME} respects your privacy. Please refer to our <a href='/privacy-policy'>Privacy Policy</a> for information on how we collect and use your personal information.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>5. Disclaimers</span> <br />
                        We are not responsible for any content or information found on any third-party websites that may be linked to this Site. In addition, we do not guarantee that access to the Site will be uninterrupted or error-free, or that the Site or its server will be free from viruses or other harmful components.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>6. Limitation of Liability</span> <br />
                        We shall not be liable for any damages or injury resulting from your access to, or inability to access, the Site, or from your reliance on any information provided at the Site.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>7. Copyrights</span> <br />
                        All content included or available on the Site, including text, graphics, logos, icons, images, audio clips, digital downloads, data compilations, and software, is the property of {process.env.REACT_APP_NAME} or its content suppliers and is protected by United States and international copyright laws.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>8. Miscellaneous</span> <br />
                        If any of these Terms is held to be invalid or unenforceable, the remaining Terms will remain in full force and effect. {process.env.REACT_APP_NAME}'s failure to act with respect to a breach by you or others does not waive its right to act with respect to subsequent or similar breaches. These Terms set forth the entire understanding and agreement between you and {process.env.REACT_APP_NAME} with respect to the Site.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>9. Payments</span> <br />
                        {process.env.REACT_APP_NAME} accepts payments via valid Credit Card, Apple Pay, CashApp, Google Pay.
                    </p>

                    <p className='poppins text-color6 mt-5'>
                        <span className='fw-semibold'>10. Refunds</span> <br />
                        We offer refunds for orders placed the same day only, and refunds will be issued to the same payment method.
                    </p>
                </div>
            </div>
        )
    }
}

export default TermsAndConditions;