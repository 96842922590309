import React from 'react';
import axios from 'axios';

class Open extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          display: "login",
          user: "",
          password: "",
          token: ""
        };
    }

    loginForm() {
        return (
            <div>
                <div className="form-group mb-3">
                    <label htmlFor="user">User</label>
                    <input type="text"  onChange={(e) => this.setValue(e, "user")} className='form-control' id='user' name='user' />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="password">Password</label>
                    <input type="password"  onChange={(e) => this.setValue(e, "password")} className='form-control' id='password' name='password' />
                </div>
                <button className='btn btn-primary' onClick={(e) => this.login()}>Login</button>
            </div>
        );
    }

    switch() {
        var locations = [];
        this.props?.locationsStatus?.forEach(status => {
            locations?.push(
                <div className="form-check form-switch p-3" key={status?.location}>
                    <input className="form-check-input" type="checkbox" role="switch" id={"openSwitch_"+status?.location} onChange={(e) => this.openOrClose(e, status?.location)} checked={status?.enabled}/>
                    <label className="form-check-label" htmlFor="openSwitch">Is {status?.store} open?</label>
                </div>
            )
        });
        return (
            <div>
                {locations}
            </div>
        );
    }

    login(){
        axios.post(process.env.REACT_APP_API_URL+'users/login', {
            "email": this.state.user,
            "password": this.state.password
        }).then(res => {
            if(res?.data?.error){
                alert(res?.data?.error);
            }
            else if (res?.data === "Incorrect Password"){
                alert(res?.data);
            }
            else{
                this.setState({
                    display: "switch",
                    token: res?.data?.token
                });
            }
        }).catch(err => console.log(err));
    }

    setValue(e, value){
        if (value === "user") this.setState({user: e.target.value});
        else this.setState({password: e.target.value});
    }

    openOrClose(e, id){
        axios.post(process.env.REACT_APP_API_URL+'location/update', {
                'location': id,
                'enabled': e.target.checked
            },
            {
                headers: {'Authorization': 'Bearer '+this.state?.token}
            }).then(res => {
                this.props.openOrClose(res?.data?.enabled);
        }).catch(err => console.log(err));
    }

    render() {
        var display = (this.state.display === "login" ? this.loginForm() : this.switch() );
        return (
            <div className='m-5 p-5'>
                <div className="m-5">
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-3 border rounded p-5">
                            {display}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Open;