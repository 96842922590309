import React from 'react';
import './assets/PickupDetails.css';
import validator from 'validator';
import TimePicker from '../time-picker/TimePicker';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import withNavigation from '../../../withNavigation/withNavigation';
import axios from 'axios';

const MapComponent = React.memo(({ loc, apiKey }) => {
  const MapWithScript = withScriptjs(withGoogleMap(() => (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{
        lat: loc?.lat,
        lng: loc?.lng
      }}
    >
      <Marker
        position={{
          lat: loc?.lat,
          lng: loc?.lng
        }}
      />
    </GoogleMap>
  )));

  return (
    <MapWithScript
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `200px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
});


class PickupDetails extends React.Component {
  async componentDidUpdate(prevProps) {
    // Handle form completion
    if (prevProps !== this.props && this.props?.user && !this.props.isFormComplete) {
      let pickup_details = this.state.pickup_details;
      pickup_details.isFormComplete = true;
      this.setState({ pickup_details });
    }

    // Only update coordinates if locations changed or location id changed
    if ((prevProps?.locations !== this.props?.locations || 
         prevProps?.location !== this.props?.location) && 
        this.props?.locations) {
          console.log(this.props.locations);
        
        try {
            const loc = this.props.locations?.find(l => l.id === this.props.location);

            if (!loc) {
                return;
            }

            // Check if coordinates are different from current state to prevent loops
            if (loc.coordinates?.latitude && loc.coordinates?.longitude) {
                const newLat = parseFloat(loc.coordinates.latitude);
                const newLng = parseFloat(loc.coordinates.longitude);
                
                if (this.state.coordinates.lat !== newLat || 
                    this.state.coordinates.lng !== newLng) {
                    this.setState({
                        coordinates: {
                            lat: newLat,
                            lng: newLng
                        }
                    });
                }
                return;
            }

            if (!loc.address) {
                return;
            }

            const addressComponents = [
                loc.address.address_line_1,
                loc.address.locality,
                loc.address.administrative_district_level_1,
                loc.address.postal_code,
                loc.address.country
            ].filter(Boolean);

            if (addressComponents.length > 0) {
                const addressString = addressComponents.join(", ");
                // Only get coordinates if address changed
                if (this._lastProcessedAddress !== addressString) {
                    this._lastProcessedAddress = addressString;
                    const coords = await this.getCoordinatesFromAddress(addressString);
                    this.setState({ coordinates: coords });
                }
            }

        } catch (error) {
            console.error('Error updating map coordinates:', error);
        }
    }
  }

  async componentDidMount() {
    const loc = this.props?.locations?.find((l) => l.id === this.props?.location);
    var address = [
      loc.address.address_line_1,
      loc.address.locality,
      loc.address.administrative_district_level_1,
      loc.address.postal_code,
      loc.address.country
    ];
    if (!loc?.coordinates) {
      this.setState({ coordinates: await this.getCoordinatesFromAddress(address.join(", ")) });
    }
    else {
      this.setState({ coordinates: { lat: loc?.coordinates?.latitude, lng: loc?.coordinates?.longitude } })
    }
  }

  state = {
    pickup_details: {
      name: this.props?.user ? this.props?.user?.name + " " + this.props?.user?.lastName : "",
      email: this.props?.user?.email,
      phone: this.props?.user?.phone,
      notes: null,
      isFormComplete: ((this.props?.user?.name + this.props?.user?.lastName + this.props?.user?.phone + this.props?.user?.phone)?.length > 0),
      scheduleType: (this.props.catalog === "regular") ? 'ASAP' : 'Schedule',
      pickupAt: new Date().toISOString(),
    },
    coordinates: { lat: 0, lng: 0 }
  };

  handleChange(e, field) {
    const value = e.target.value;
    this.setState((prevState) => ({
      pickup_details: {
        ...prevState.pickup_details,
        [field]: value,
        isFormComplete: this.isFormComplete({
          ...prevState.pickup_details,
          [field]: value,
        }),
      }
    }));
  }

  isFormComplete(pickupDetails) {
    const { name, email, phone } = pickupDetails;
    return (
      name !== '' &&
      email !== '' &&
      phone !== '' &&
      validator.isAlpha(name || '', 'en-US', { ignore: 's' }) &&
      validator.isEmail(email || '') &&
      validator.isMobilePhone(phone || '')
    );
  }

  handleSubmit = () => {
    const { name, email, phone } = this.state.pickup_details;
    const isFormComplete = name !== null && email !== null && phone !== null;
    this.setState({ isFormComplete });
    this.props?.addPickupDetails(this.state);
  };

  handlePickupTimeChange = (time) => {
    const pickupHour = time.getHours();
    const pickupMinute = time.getMinutes();

    if (pickupHour > 11 || (pickupHour === 11 && pickupMinute >= 0)) {
      if (pickupHour < 20 || (pickupHour === 20 && pickupMinute === 0)) {
        this.setState((prevState) => ({
          pickup_details: {
            ...prevState.pickup_details,
            pickupAt: time.toISOString(),
          },
        }));
      } else {
        // Set the state to 8:00 PM if the time is after 8 PM
        const maxTime = new Date();
        maxTime.setHours(20);
        maxTime.setMinutes(0);
        maxTime.setSeconds(0);
        maxTime.setMilliseconds(0);

        this.setState((prevState) => ({
          pickup_details: {
            ...prevState.pickup_details,
            pickupAt: maxTime.toISOString(),
          },
        }));
      }
    } else {
      // Set the state to 11:00 AM if the time is before 11 AM
      const minTime = new Date();
      minTime.setHours(11);
      minTime.setMinutes(0);
      minTime.setSeconds(0);
      minTime.setMilliseconds(0);

      this.setState((prevState) => ({
        pickup_details: {
          ...prevState.pickup_details,
          pickupAt: minTime.toISOString(),
        },
      }));
    }
  };

  handleScheduleTypeChange = (scheduleType) => {
    this.setState((prevState) => ({
      pickup_details: {
        ...prevState.pickup_details,
        scheduleType,
      },
    }));
  };

  goToCheckout = () => {
    this.props.navigate("/checkout/pickup");
  };

  async getCoordinatesFromAddress(address) {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const location = data.results[0].geometry.location;
      return { lat: location.lat, lng: location.lng };
    } else {
      throw new Error('No se pudieron obtener las coordenadas');
    }
  };

  async getProfile() {
    const jwt = localStorage.getItem("jwt");
    try {
      if (jwt) {
        const response = await axios.post(process.env.REACT_APP_API_URL + "customer/get-user-details", { jwt });
        if (response) {
          this.setState({
            pickup_details: {
              name: `${response.data.name} ${response.data.lastName}`,
              email: response.data.username,
              phone: response.data.phone,
              notes: null,
              isFormComplete: ((response.data.name + response.data.lastName + response.data.username + response.data.phone)?.length > 0),
              scheduleType: (this.props.catalog === "regular") ? 'ASAP' : 'Schedule',
              pickupAt: new Date().toISOString(),
            }
          });
        }
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  render() {

    const busyMode = this.props?.attributes?.busy_mode;
    let prepTime = busyMode ? this.props?.attributes?.preptime + 10 : this.props?.attributes?.preptime;
    //TEST

    if (localStorage.getItem("jwt") && localStorage.getItem("jwt").length > 0 && localStorage.getItem("jwt") !== "undefined" && !this.state.pickup_details.email) {
      this.getProfile()
    }
    const duration =
      this.props?.order?.fulfillments ? (
        <div className="col-6 text-end">
          {parseInt(this.props?.order?.fulfillments[0]?.pickupDetails?.prepTimeDuration.substr(2, 2)) - 5 || 25}-
          {parseInt(this.props?.order?.fulfillments[0]?.pickupDetails?.prepTimeDuration.substr(2, 2)) + 5 || 25}min.
        </div>
      ) : (
        <div className="col-6 text-end">
          {prepTime - 5} - {prepTime + 5} min.
        </div>
      );
    return (
      <div className="wrapper mb-2 pt-3">
        <MapComponent loc={this.state.coordinates} apiKey={process.env.REACT_APP_GOOGLE_API_KEY} />
        <input
          className="w-100 my-2"
          type="text"
          value={this.state.pickup_details.name}
          onChange={(e) => this.handleChange(e, 'name')} 
          onBlur={(e) => this.setState(prev => ({
            pickup_details: {
              ...prev.pickup_details,
              nameWasTouched: true
            }
          }))}
          placeholder="Full name"
          required
        />
        {this.state?.pickup_details?.nameWasTouched && 
         this.state?.pickup_details?.name !== null && 
         !validator.isAlpha(this.state?.pickup_details?.name || '', 'en-US', { ignore: ' ' }) && (
          <div className="error">Name not valid.</div>
        )}

        <input
          className="w-100 my-2"
          type="text"
          value={this.state.pickup_details.email}
          onChange={(e) => this.handleChange(e, 'email')}
          onBlur={(e) => this.setState(prev => ({
            pickup_details: {
              ...prev.pickup_details,
              emailWasTouched: true
            }
          }))}
          placeholder="Email"
        />
        {this.state?.pickup_details?.emailWasTouched &&
         this.state?.pickup_details?.email !== null && 
         !validator.isEmail(this.state?.pickup_details?.email || '') && (
          <div className="error">Email is not valid.</div>
        )}

        <input
          className="w-100 my-2"
          type="text"
          value={this.state.pickup_details.phone}
          onChange={(e) => this.handleChange(e, 'phone')}
          onBlur={(e) => this.setState(prev => ({
            pickup_details: {
              ...prev.pickup_details,
              phoneWasTouched: true
            }
          }))}
          placeholder="Phone number"
        />
        {this.state?.pickup_details?.phoneWasTouched &&
         this.state?.pickup_details?.phone !== null &&
         !validator.isMobilePhone(this.state?.pickup_details?.phone || '') && (
          <div className="error">Phone number is not valid.</div>
        )}

        <hr />

        {this.props?.order?.fulfillments ?
          <div className="row poppins text-color6">
            <div className="col-6">Pickup estimate</div>
            {duration}
          </div> :
          <TimePicker blockDelivery={this.props.catalog !== "regular"} onPickupTimeChange={this.handlePickupTimeChange} onScheduleTypeChange={this.handleScheduleTypeChange} scheduleType={this.state.pickup_details.scheduleType} pickupAt={this.state.pickup_details.pickupAt} />
        }

        <br />
        <br />

        <button
          className="w-100 btn bg-color4 text-color6"
          onClick={this.handleSubmit}
          disabled={!this.state?.pickup_details?.isFormComplete}
        >
          Continue to payment
        </button>
      </div>
    );
  }
}

export default withNavigation(PickupDetails);